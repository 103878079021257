import React, { Component } from 'react';
import axios from 'axios';
import { appConfig } from "../utils/config.js";
import { getSessionId } from "../utils/sessionUtil.js";
import SIWrapper from "../layouts/signedin.layout";
import { Tabs, Tab, Modal, Button } from 'react-bootstrap';

export default class UserHome extends Component {

	constructor(props) {
		super(props);
		this.state = {
			adminName: '',
			coaches: [],
			aicUsers: [],
			invitations: [],
			error: false,
			showInviteCoach: false,
			showInviteAICoachedUser: false,
			doneloading: false
		}
	}

	componentDidMount = (e) => {
		this.loadAdminHome();
	}

	loadAdminHome = () => {
		// Validate the session
		let sessionId = getSessionId();
		axios.post(appConfig.apihost+'/api/load-admin-home', {sessionId: sessionId})
		.then(response => {
			if (response.data.invalidSession) {
		        this.props.history.push("/logout");
			}
			else if (response.data.success) {
				this.setState({
					coaches: response.data.coaches,
					aicUsers: response.data.aicUsers,
					invitations: response.data.invitations,
					adminName: response.data.session.user_name,
					adminType: response.data.session.user_type,
					doneloading: true
				});
			}
			else {
				this.setState({
					error: true
				});
			}
		})
		.catch(error => {
			console.log('error',error);
		});
	}

	formatDate = (dateStr) => {
		let date = new Date(dateStr);
		let day = date.getDate();
	  	let month = date.getMonth() +1;
	  	let year = date.getFullYear();
		return month+'-'+day+'-'+year;
	}

    coachList = () => {
        return this.state.coaches.map((currentCoach, i) => {
            return  ( <tr key={i}><td>{currentCoach.first_name}</td><td>{currentCoach.last_name}</td><td>{currentCoach.email}</td></tr> );
	    });
    }

since_last_access

    aicUserList = () => {
        return this.state.aicUsers.map((aicUser, i) => {
            return  ( <tr key={i}><td>{aicUser.first_name}</td><td>{aicUser.last_name}</td><td>{aicUser.access_perday_average}</td><td>{aicUser.activity_complete_percentage}%</td><td>{aicUser.since_last_access}</td></tr> );
	    });
    }

    invitationList = () => {
        return this.state.invitations.map((currentInvite, i) => {
			let type = 'Coach';
			if (currentInvite.user_type === 'user') type = 'AI Coached User';
            return  ( <tr key={i}><td>{type}</td><td>{currentInvite.email}</td><td>{this.formatDate(currentInvite.creation_date)}</td><td>{this.formatDate(currentInvite.expire_date)}</td></tr> );
	    });
    }

	handleCloseInvite = () => {
		this.setState({
			showInviteCoach: false,
			showInviteAICoachedUser: false
		});
	}

	handleShowInviteCoach = () => {
		this.setState({
			invitationEmail: '',
			inviteAICoachedUser: false,
			showInviteCoach: true
		});
	}

	handleShowInviteAICoachedUser = () => {
		this.setState({
			invitationEmail: '',
			inviteAICoachedUser: true,
			showInviteAICoachedUser: true
		});
	}

    onChangeInvitation = (e) => {
        this.setState({
            invitationEmail: e.target.value
        });
    }

    onSendInvitation = (e) => {
		// Validate the session
		let sessionId = getSessionId();
		let type = 'coach';
		if (this.state.inviteAICoachedUser) type = 'user';
		axios.post(appConfig.apihost+'/api/send-special-invitation', {sessionId: sessionId, type: type, emailToInvite: this.state.invitationEmail})
		.then(
			this.loadAdminHome()
		)
		.catch(error => {
			console.log('error',error);
		});
		this.handleCloseInvite();
	}

	render() {
		if (!this.state.doneloading) {
			return (
				<br/>
			)
		}
		else if (this.state.error) {
			return (
				<SIWrapper name={this.state.adminName} showbackground={true}>
					<p>Server Error</p>
				</SIWrapper>
			)
		}
		else {
			return (
				<SIWrapper name={this.state.adminName} showbackground={true}>
					<div style={{marginTop: 10}}>
						<h2>Admin Home</h2>
						<Tabs defaultActiveKey="coaches">
							<Tab eventKey="coaches" title="Coaches">
								<table className="table table-striped">
									<thead>
										<tr>
											<th>First Name</th>
											<th>Last Name</th>
											<th>Email</th>
										</tr>
									</thead>
									<tbody>
										{ this.coachList() }
									</tbody>
								</table>
							</Tab>

							{ this.state.adminType === 'super-admin' &&

							<Tab eventKey="aicusers" title="AI Coached Users">
								<table className="table table-striped">
									<thead>
										<tr>
											<th>First Name</th>
											<th>Last Name</th>
											<th>Uses/Day</th>
											<th>Activities</th>
											<th>Last Access</th>
										</tr>
									</thead>
									<tbody>
										{ this.aicUserList() }
									</tbody>
								</table>
							</Tab>

							}
							
							<Tab eventKey="invitations" title="Invitations">
								<table className="table table-striped">
									<thead>
										<tr>
											<th>Type</th>
											<th>Email</th>
											<th>Sent</th>
											<th>Expires</th>
										</tr>
									</thead>
									<tbody>
										{ this.invitationList() }
									</tbody>
								</table>
							</Tab>
							<Tab eventKey="actions" title="Actions">
								<p></p>
								<Button variant="secondary" onClick={this.handleShowInviteCoach}>
									Invite Coach
								</Button>
								<p></p>
								{ this.state.adminType === 'super-admin' &&
									<Button variant="secondary" onClick={this.handleShowInviteAICoachedUser}>
										Invite AI Coached User
									</Button>
								}						

								<Modal show={this.state.showInviteCoach} onHide={this.handleCloseInvite}>
								  <Modal.Header closeButton>
									<Modal.Title>Coach's Email Address</Modal.Title>
								  </Modal.Header>
			                    	<input type="email" placeholder={"Enter email"} style={{margin: "5px 35px 5px 35px"}} onChange={this.onChangeInvitation}/>
								  <Modal.Footer>
									<Button variant="secondary" onClick={this.handleCloseInvite}>
									  Cancel
									</Button>
									<Button variant="primary" onClick={this.onSendInvitation}>
									  Send Invitation
									</Button>
								  </Modal.Footer>
								</Modal>

								<Modal show={this.state.showInviteAICoachedUser} onHide={this.handleCloseInvite}>
								  <Modal.Header closeButton>
									<Modal.Title>AI Coached User's Email Address</Modal.Title>
								  </Modal.Header>
			                    	<input type="email" placeholder={"Enter email"} style={{margin: "5px 35px 5px 35px"}} onChange={this.onChangeInvitation}/>
								  <Modal.Footer>
									<Button variant="secondary" onClick={this.handleCloseInvite}>
									  Cancel
									</Button>
									<Button variant="primary" onClick={this.onSendInvitation}>
									  Send Invitation
									</Button>
								  </Modal.Footer>
								</Modal>
								
							</Tab>
						</Tabs>
					</div>
				</SIWrapper>
			)
		}
    }
}
import React, { Component } from 'react';
import axios from 'axios';
import { appConfig } from "../utils/config.js";
import { getSessionId } from "../utils/sessionUtil.js";
import SIWrapper from "../layouts/signedin.layout";
import Timekeeper from 'react-timekeeper';
import { Modal, Button, Form } from 'react-bootstrap';

import EditImg from "../images/edit.png";
import DeleteImg from "../images/delete.png";

export default class Reminders extends Component {

	constructor(props) {
		super(props);
		this.state = {
			DOWNames: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
			editReminderDOWSelected: [],
			reminderEditTimes: [],
			showTimePicker: false,
			name: '',
			remindersUserId: '',
			coachedUser: this.props.match.params.usr,
			coachedUserName: '',
			error: false,
			doneloading: false
		}
	}

	componentDidMount = (e) => {
		this.loadReminders();
	}

	loadReminders = () => {
		// Validate the session
		let sessionId = getSessionId();
		axios.post(appConfig.apihost+'/api/get-reminders/', {sessionId: sessionId, userId: this.state.coachedUser})
		.then(response => {
			if (response.data.invalidSession) {
		        this.props.history.push("/logout");
			}
			else if (response.data.success) {
				this.setState({
					name: response.data.session.user_name,
					remindersUserId: response.data.userId,
					coachedUserName: response.data.coachedUserName,
					reminderTypes: response.data.reminderTypes,
					reminders: response.data.reminders,
					doneloading: true
				});
			}
			else {
				this.setState({
					error: true
				});
			}
		})
		.catch(error => {
			console.log('error',error);
		});
	}

	showAddReminder = () => {
		this.setState({ 
			reminderModalEditType: 'Create',
			editReminderId: null,
			reminderEditType: this.state.reminderTypes[0].type,
			reminderEditSupplements: '',
			editReminderDOWSelected: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
			reminderEditTimes: [{hour: 9, minute: 0}],
			reminderModalShow: true
		 });
	}

	showUpdateReminder = (reminderId) => {
		// find the reminder
		let i;
		for (i = 0; i < this.state.reminders.length; i++) {
			if (this.state.reminders[i].id === reminderId) break;
		}

		this.setState({ 
			reminderModalEditType: 'Update',
			editReminderId: reminderId,
			reminderEditType: this.state.reminders[i].type,
			reminderEditSupplements: this.state.reminders[i].supplement_list,
			editReminderDOWSelected: this.state.reminders[i].days.slice(),
			// do a deep copy
			reminderEditTimes: this.state.reminders[i].times.map(a => ({...a})),
			reminderModalShow: true
		 });
	}

    onChangeReminderEditSupplements = (e) => {
        this.setState({
            reminderEditSupplements: e.target.value
        });
    }

	reminderTypeClick = (e) => {
		this.setState({
			reminderEditType: e.target.value
		});
	}

	handleClickEditDOW = (e) => {
		let newDOWSelected = this.state.editReminderDOWSelected;
		let i = newDOWSelected.indexOf(e.target.value);
		if (i === -1) {
			newDOWSelected.push(e.target.value);
		}
		else if (newDOWSelected.length > 1) newDOWSelected.splice(i, 1);

		let gdn = function(dayStr) {
			let dn = 7;
			switch(dayStr) {
			  case 'Monday':
				dn = 1;
				break;
			  case 'Tuesday':
				dn = 2;
				break;
			  case 'Wednesday':
				dn = 3;
				break;
			  case 'Thursday':
				dn = 4;
				break;
			  case 'Friday':
				dn = 5;
				break;
			  case 'Saturday':
				dn = 6;
			}
		return dn;
		};
		// sort so the days are always in order
		newDOWSelected.sort(function(a, b) {
			let av = gdn(a);
			let bv = gdn(b);
			return av-bv;
		});
        this.setState({
            editReminderDOWSelected: newDOWSelected,
        });
	}
	
	handleEditReminderModalHide = () => {
		this.setState({ reminderModalShow: false });
	}

	addOrEditReminder = () => {
		// Validate the session
		let sessionId = getSessionId();
		let sl;
		if (this.state.reminderEditType === 'take-supplements') sl = this.state.reminderEditSupplements;
		else sl = '';
		let newReminder = {
			sessionId: sessionId,
			reminderId: this.state.editReminderId,
            remindersUserId: this.state.remindersUserId,
			type: this.state.reminderEditType,
			supplementList: sl,
			DOWSelected: this.state.editReminderDOWSelected,
			reminderTimes: this.state.reminderEditTimes
		};
		axios.post(appConfig.apihost+'/api/addorupdate-reminder', newReminder)
		.then(response => {
			this.handleEditReminderModalHide();
			this.loadReminders();
		})
		.catch(error => {
			this.handleEditReminderModalHide();
			console.log('error',error);
		});
	}

 	deleteReminder = () => {
		this.handleConfirmReminderDeleteModalHide();
		// Validate the session
		let sessionId = getSessionId();
		// send the data
		let reminderData = {
			sessionId: sessionId,
            reminderId: this.state.reminderId
		}
		axios.post(appConfig.apihost+'/api/delete-reminder/', reminderData)
		.then(response => {
			if (response.data.invalidSession) {
		        this.props.history.push("/logout");
			}
			else if (response.data.success) {
				this.loadReminders();
			}
			else {
				this.setState({
					error: true
				});
			}
		})
		.catch(error => {
			console.log('error',error);
		});
	}

	handleConfirmReminderDeleteModalShow = (reminderId) => {
		this.setState({ 
			confirmReminderDeleteModalShow: true,
			reminderId: reminderId
		});
	}

	handleConfirmReminderDeleteModalHide = () => {
		this.setState({ confirmReminderDeleteModalShow: false });
	}

	addTime = () => {
		this.setState({
			showTimePicker: true,
			timePickerMode: 'add',
			timePickerTime: { hour: 9, minute: 0},
		});	
	}

	editTime = (hour, minute) => {
		this.setState({
			showTimePicker: true,
			timePickerMode: 'edit',
			timePickerTime: { hour: hour, minute: minute},
			editHour: hour,
			editMinute: minute
		});	
	}

	deleteTime = (hour, minute) => {
		for (let i = 0; i < this.state.reminderEditTimes.length; i++) {
			if (this.state.reminderEditTimes[i].hour === hour && this.state.reminderEditTimes[i].minute === minute) {
				this.state.reminderEditTimes.splice(i,1);
				// force update
				this.setState({ reminderEditTimes: this.state.reminderEditTimes });			
				break;
			}
		}
	}
	
	doneTimePicker = (timeInfo) => {
		if (this.state.timePickerMode === 'add') {
			this.state.reminderEditTimes.push({hour: timeInfo.hour, minute: timeInfo.minute});
		}
		else {
			for (let i = 0; i < this.state.reminderEditTimes.length; i++) {
				if (this.state.reminderEditTimes[i].hour === this.state.editHour && this.state.reminderEditTimes[i].minute === this.state.editMinute) {
					this.state.reminderEditTimes[i].hour = timeInfo.hour;
					this.state.reminderEditTimes[i].minute = timeInfo.minute;
					break;
				}
			}
		}
		this.state.reminderEditTimes.sort(function(a, b) {
			let at = (a.hour*60)+a.minute;
			let bt = (b.hour*60)+b.minute;
			return at - bt;
		});
		this.setState({
			showTimePicker: false
		 });
	}
	
	handleChooseTimeModalHide = () => {
		this.setState({ chooseTimeModalShow: false });
	}
		
    reminderTypeItems = () => {
        return this.state.reminderTypes.map((reminderType, i) => {
            return  ( <option key={i} selected={this.state.reminderEditType === reminderType.type} value={reminderType.type}>{reminderType.description}</option> );
	    });
    }

    displayDOWItems = () => {
        return this.state.DOWNames.map((dow, i) => {
			return ( 
				<Form.Check key={i} value={dow} label={dow} checked={ this.state.editReminderDOWSelected.indexOf(dow) >= 0} onChange={this.handleClickEditDOW} />
			 )
	    });
    }

    displayTimes = () => {
        return this.state.reminderEditTimes.map((time, i) => {
			let meridiem = ' am';
			let hour = time.hour;
			let minute = time.minute;
			if (hour === 0) {
				hour = 12;
			}
			else if (hour > 11) {
				meridiem = ' pm';
				if (hour > 12) hour -= 12;
			}
			if (minute < 10) minute = '0'+minute;
			let timeStr = hour+':'+minute+meridiem;
            return  ( <tr key={i}><td>{timeStr}</td><td width="50"><input type="button" style={{height: "20px", width: "20px", background: "url("+EditImg+")", border: "none", outline: "0", backgroundSize: "contain"}} onClick={() => {this.editTime(time.hour, time.minute)}}/></td><td width="50"><input type="button" style={{height: "20px", width: "20px", background: "url("+DeleteImg+")", border: "none", outline: "0", backgroundSize: "contain"}} onClick={() => {this.deleteTime(time.hour, time.minute)}}/></td></tr>);						
	    });
    }

   reminderList = () => {
        return this.state.reminders.map((reminder, i) => {
			let timeStr = '';
			let dayStr;
			if (reminder.days.length === 7) dayStr = 'Every day';
			else dayStr = reminder.days.toString();
			let hour, minute, meridiem;			
			for (let i2 = 0; i2 < reminder.times.length; i2++) {
				meridiem = ' am';
				hour = reminder.times[i2].hour;
				minute = reminder.times[i2].minute;
				if (hour === 0) {
					hour = 12;
				}
				else if (hour > 11) {
					meridiem = ' pm';
					if (hour > 12) hour -= 12;
				}
				if (minute < 10) minute = '0'+minute;
				if (i2 > 0) timeStr += ', ';
				timeStr += hour+':'+minute+meridiem;
			}
			let rType;
			if (reminder.type === 'drink-water') rType = 'Drink Water';
			else rType = 'Take Supplements';
            return  ( <tr key={i}><td>{rType}</td><td>{reminder.supplement_list}</td><td>{dayStr}</td><td>{timeStr}</td><td width="50"><input type="button" style={{height: "20px", width: "20px", background: "url("+EditImg+")", border: "none", outline: "0", backgroundSize: "contain"}} onClick={() => {this.showUpdateReminder(reminder.id)}}/></td><td width="50"><input type="button" style={{height: "20px", width: "20px", background: "url("+DeleteImg+")", border: "none", outline: "0", backgroundSize: "contain"}} onClick={() => {this.handleConfirmReminderDeleteModalShow(reminder.id)}}/></td></tr>);						
	    });
    }

	render() {
		if (!this.state.doneloading) {
			return (
				<br/>
			)
		}
		else if (this.state.error) {
			return (
				<SIWrapper name={this.state.coachName} showbackground={true}>
					<p>Server Error</p>
				</SIWrapper>
			)
		}
		else {
			return (
				<SIWrapper name={this.state.name} width="1100px" showbackground={true}>
					<div style={{marginTop: 10}}>
						<table width="100%"><tbody><tr><td>
							{this.state.coachedUser ? 
								<>Reminders for <b>{this.state.coachedUserName}</b></>
								:
								<b>Reminders</b>
							}
						</td>
						<td align="right">
							<Button variant="outline-secondary"  onClick={this.showAddReminder}>Add Reminder</Button>
						</td></tr></tbody></table><br/>

						<table className="table table-striped">
							<thead>
								<tr>
									<th>Type</th>
									<th></th>
									<th>Days</th>
									<th>Times</th>
									<th></th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{ this.reminderList() }
							</tbody>
						</table>

						<Modal show={this.state.reminderModalShow}
							dialogClassName="modal-90w"
							aria-labelledby="example-custom-modal-styling-title">
						<Modal.Header>
							<Modal.Title id="example-custom-modal-styling-title">{this.state.reminderModalEditType === "Create" ? "New" : "Update"} Reminder</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							{this.state.showTimePicker &&
								<div style={{position: "absolute", top: "0px", left: "180px", zIndex: "10"}}>
									<Timekeeper time={this.state.timePickerTime} onDoneClick={this.doneTimePicker}/>
								</div>
							}
							<table><thead></thead><tbody><tr>
							<td valign="top" width="355px">
								Reminder Type
								<div style={{height: "5px"}}></div>
								<select onChange={this.reminderTypeClick}>>
									{ this.reminderTypeItems() }
								</select>
								<br/>

								{ this.state.reminderEditType === 'take-supplements' &&
									<div className="form-group" style={{width: "325px"}}> 
										<div style={{height: "5px"}}></div>
										<input type="text"
											className="form-control"
											value={this.state.reminderEditSupplements}
											onChange={this.onChangeReminderEditSupplements}
										/>
									</div>
								}

								<div style={{height: "10px"}}></div>
								<table width="320px"><tr><td><b>Times</b></td><td align="right"><Button variant="outline-secondary"  onClick={this.addTime}>Add Time</Button></td></tr></table>
								<div style={{height: "10px"}}></div>
								<table className="table table-striped" style={{width: "320px"}}>
									<thead>
										<tr>
										</tr>
									</thead>
									<tbody>
										{ this.displayTimes() }
									</tbody>
								</table>
							</td>
							<td valign="top">
							<label>Days of Week</label>
							{ this.displayDOWItems() }
							</td>
							</tr></tbody></table>							
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={this.handleEditReminderModalHide} disabled={this.state.showTimePicker} >Cancel</Button>
							{ this.state.reminderModalEditType === "Create" ?
								<Button variant="primary" onClick={this.addOrEditReminder} disabled={this.state.showTimePicker}>Add Reminder</Button>
								:
								<Button variant="primary" onClick={this.addOrEditReminder} disabled={this.state.showTimePicker}>Update Reminder</Button>					
							}
						</Modal.Footer>
						</Modal>

						<Modal show={this.state.confirmReminderDeleteModalShow}
							dialogClassName="modal-90w"
							aria-labelledby="example-custom-modal-styling-title">
						<Modal.Header>
							<Modal.Title id="example-custom-modal-styling-title">Delete Reminder {this.state.reminderNameToDelete}?</Modal.Title>
						</Modal.Header>
						<Modal.Footer>
							<Button variant="secondary" onClick={this.handleConfirmReminderDeleteModalHide}>Cancel</Button>
							<Button variant="primary" onClick={this.deleteReminder}>Delete</Button>
						</Modal.Footer>
						</Modal>
					</div>
				</SIWrapper>
			)
		}
    }
}
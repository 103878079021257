import { appConfig } from "../utils/config.js";
import axios from 'axios';

export default function sessionFunctions() {
}

export function getSessionId() {
	let sessionInfo;
	let siStr = localStorage.getItem('yay4me.com');
	try {
		sessionInfo = JSON.parse(siStr)
	}
	catch (e) {
	}
	
	if (sessionInfo) {
		// refresh the session every minute
		if (new Date().getTime() > (sessionInfo.refreshed + (1000 * 60))) {
			sessionInfo.refreshed = new Date().getTime();
			localStorage.setItem('yay4me.com', JSON.stringify(sessionInfo));
			axios.post(appConfig.apihost+'/api/refresh-session/', {sessionId: sessionInfo.sessionId, accessType: 'web'})
			.then()
			.catch(error => {
				console.log('error',error);
			});
		}
		return sessionInfo.sessionId;
	}
	else {
		return null;
	}
};

export function saveSessionId(sessionId) {
	let sessionInfo = {
		sessionId: sessionId,
		refreshed: 0
	};
	localStorage.setItem('yay4me.com', JSON.stringify(sessionInfo));
};

export function removeSessionId() {
	localStorage.removeItem('yay4me.com');
};


import React, { Component } from 'react';

export default class NotFound extends Component {

	render() {
		return (
			<p>Page not found</p>
		);
	}

}
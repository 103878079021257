import React, { Component } from 'react';

import Background from "../images/sunset.jpg";

export default class SUSIWrapperComponent extends Component {
  render() {
    return (
		<div style={{height: "1500px", backgroundImage: "url(" + Background + ")", backgroundRepeat: "no-repeat", backgroundSize: "contain"}}>
			<div style={{padding: "20px 25px 20px", width: "350px", height: "auto", float: "right", backgroundColor: "#eee", marginRight: "75px", marginTop: "75px"}}>
				{this.props.children}
      		</div>
      	</div>
    );
  }
}

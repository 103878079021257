import React, { Component } from 'react';
import axios from 'axios';
import { appConfig } from "../utils/config.js";
import { getSessionId } from "../utils/sessionUtil.js";
import SIWrapper from "../layouts/signedin.layout";
import { Tabs, Tab, Modal, Button, Form } from 'react-bootstrap';

import CalendarImg from "../images/calendar.png";
import RemindersImg from "../images/reminders.png";
import DeleteImg from "../images/delete.png";

export default class CoachHome extends Component {

	constructor(props) {
		super(props);
		this.state = {
			users: [],
			invitations: [],
			seriesNames: [],
			coachName: '',
			confirmDeleteModalShow: false,
			seriesToDelete: '',
			error: false,
			doneloading: false
		}
	}

	componentDidMount = (e) => {
		this.loadCoachHome();
	}

	loadCoachHome = () => {
		// Validate the session
		let sessionId = getSessionId();
		axios.post(appConfig.apihost+'/api/load-coach-home/', {sessionId: sessionId})
		.then(response => {
			if (response.data.invalidSession) {
		        this.props.history.push("/logout");
			}
			else if (response.data.success) {
				this.setState({
					users: response.data.users,
					invitations: response.data.invitations,
					seriesNames: response.data.seriesNames,
					coachName: response.data.session.user_name,
					doneloading: true
				});
			}
			else {
				this.setState({
					error: true
				});
			}
		})
		.catch(error => {
			console.log('error',error);
		});
	}

	handleConfirmDeleteModalShow = (seriesName) => {
		this.setState({ 
			confirmDeleteModalShow: true,
			seriesToDelete: seriesName,
		});
	}

	handleConfirmDeleteModalHide = () => {
		this.setState({ confirmDeleteModalShow: false });
	}
		
	deleteSeries = () => {
		// Validate the session
		let sessionId = getSessionId();
		axios.post(appConfig.apihost+'/api/delete-series/', {sessionId: sessionId, seriesToDelete: this.state.seriesToDelete})
		.then(response => {
			if (response.data.success) {
				this.loadCoachHome();
			}
			else {
				this.setState({
					error: true
				});
			}
		})
		.catch(error => {
			console.log('error',error);
		});
		this.handleConfirmDeleteModalHide();
	}

	formatDate = (dateStr) => {
		let date = new Date(dateStr);
		let day = date.getDate();
	  	let month = date.getMonth() +1;
	  	let year = date.getFullYear();
		return month+'-'+day+'-'+year;
	}

    userList = () => {
        return this.state.users.map((currentUser, i) => {
            let calUrl = '/calendar/'+currentUser.id;
            let remUrl = '/reminders/'+currentUser.id;
            return  ( <tr key={i}><td>{currentUser.first_name}</td><td>{currentUser.last_name}</td><td>{currentUser.email}</td><td><a href={remUrl}><img alt="" width="22" height="22" src={RemindersImg}/></a></td><td><a href={calUrl}><img alt="" width="20" height="20" src={CalendarImg}/></a></td></tr>);
	    });
    }

    invitationList = () => {
        return this.state.invitations.map((currentInvite, i) => {
            return  ( <tr key={i}><td>{currentInvite.email}</td><td>{this.formatDate(currentInvite.creation_date)}</td><td>{this.formatDate(currentInvite.expire_date)}</td></tr> );
	    });
    }

    seriesList = () => {
        return this.state.seriesNames.map((series, i) => {
            return  ( <tr key={i}><td>{series}</td><td align="right"><input type="button" style={{height: "20px", width: "20px", background: "url("+DeleteImg+")", border: "none", outline: "0", backgroundSize: "contain"}} onClick={() => this.handleConfirmDeleteModalShow(series)}/></td></tr> );
	    });
    }

	handleClose = () => {
		this.setState({ show: false });
	}

	handleShow = () => {
		this.setState({ show: true });
	}

    onChangeInvitation = (e) => {
        this.setState({
            invitationEmail: e.target.value
        });
    }

    onSendInvitation = (e) => {
		// Validate the session
		let sessionId = getSessionId();
		axios.post(appConfig.apihost+'/api/send-user-invitation', {sessionId: sessionId, emailToInvite: this.state.invitationEmail})
		.then(
			this.loadCoachHome()
		)
		.catch(error => {
			console.log('error',error);
		});
		this.handleClose();
	}

	render() {
		if (!this.state.doneloading) {
			return (
				<br/>
			)
		}
		else if (this.state.error) {
			return (
				<SIWrapper name={this.state.coachName} showbackground={true}>
					<p>Server Error</p>
				</SIWrapper>
			)
		}
		else {
			return (
				<SIWrapper name={this.state.coachName} showbackground={true}>
					<div style={{marginTop: 10}}>
						<h2>Coach Home</h2>
						<Tabs defaultActiveKey="users">
							<Tab eventKey="users" title="Users">
								<table className="table table-striped">
									<thead>
										<tr>
											<th>First Name</th>
											<th>Last Name</th>
											<th>Email</th>
											<th></th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										{ this.userList() }
									</tbody>
								</table>
							</Tab>
							<Tab eventKey="invitations" title="Invitations">
								<table className="table table-striped">
									<thead>
										<tr>
											<th>Email</th>
											<th>Sent</th>
											<th>Expires</th>
										</tr>
									</thead>
									<tbody>
										{ this.invitationList() }
									</tbody>
								</table>
							</Tab>
							<Tab eventKey="series" title="Activity Series">
								<table className="table table-striped">
									<thead>
										<tr>
											<th>Series Name</th>
										</tr>
									</thead>
									<tbody>
										{ this.seriesList() }
									</tbody>
								</table>
							</Tab>
							<Tab eventKey="actions" title="Actions">
								<p></p>
								<Button variant="secondary" onClick={this.handleShow}>
									Invite User
								</Button>
								<Modal show={this.state.show} onHide={this.handleClose}>
								  <Modal.Header closeButton>
									<Modal.Title>User's Email Address</Modal.Title>
								  </Modal.Header>
			                    	<input type="email" placeholder={"Enter email"} style={{margin: "15px 35px 5px 35px"}} onChange={this.onChangeInvitation}/>
								  <Modal.Footer>
									<Button variant="secondary" onClick={this.handleClose}>
									  Cancel
									</Button>
									<Button variant="primary" onClick={this.onSendInvitation}>
									  Send Invitation
									</Button>
								  </Modal.Footer>
								</Modal>
							</Tab>
						</Tabs>
						
						<Modal show={this.state.confirmDeleteModalShow}
							dialogClassName="modal-90w"
							aria-labelledby="example-custom-modal-styling-title">
						<Modal.Header>
							<Modal.Title id="example-custom-modal-styling-title">Delete Series {this.state.seriesToDelete}?</Modal.Title>
						</Modal.Header>
						<Modal.Footer>
							<Button variant="secondary" onClick={this.handleConfirmDeleteModalHide}>Cancel</Button>
							<Button variant="primary" onClick={this.deleteSeries}>Delete</Button>
						</Modal.Footer>
						</Modal>
					</div>
				</SIWrapper>
			)
		}
    }
}
import React, { Component } from 'react';
import axios from 'axios';
import { appConfig } from "../utils/config.js";
import { saveSessionId } from "../utils/sessionUtil.js";
import SUSIWrapper from "../layouts/signupsignin.layout";

export default class Signup extends Component {

	constructor(props) {
		super(props);
	
		this.state = {
			email: '',
			password: '',
			confirmPassword: '',
			signupError: false,
			signupErrorMessage: '',
			validToken: false,
			doneloading: false,
			width: window.innerWidth 
		}
	}

	componentDidMount = (e) => {
		window.addEventListener('resize', this.updateWindowWidth);
		// Validate token
		axios.post(appConfig.apihost+'/api/user-signup-check/', {token: this.props.match.params.tkn})
		.then(response => {
			this.setState({
				validToken: response.data.valid,
				doneloading: true
			});
		})
		.catch(error => {
			console.log('error',error)
		});
	}

	componentWillUnmount = () => {
		window.removeEventListener('resize', this.updateWindowWidth);
	}

    onChangeEmail = (e) => {
        this.setState({
            email: e.target.value
        });
    }

    onChangePassword = (e) => {
        this.setState({
            password: e.target.value
        });
    }

    onChangeConfirmPassword = (e) => {
        this.setState({
            confirmPassword: e.target.value
        });
    }

	updateWindowWidth = () => {
		this.setState({ width: window.innerWidth });
	}

	onSubmit = (e) => {
		e.preventDefault();
        const signupValues = {
            email: this.state.email,
            password: this.state.password,
            confirmPassword: this.state.confirmPassword,
            token: this.props.match.params.tkn
        };
        axios.post(appConfig.apihost+'/api/user-signup', signupValues)
        .then(response => {			
			if (response.data.success) {
				saveSessionId(response.data.sessionId);
		        this.props.history.push("/userhome");
			}
			else {
				this.setState({
					signupError: true,
					signupErrorMessage: response.data.message
				});
			}
        })
		.catch(error => {
			console.log('error:',error);
		});
	}

	render() {

		if (!this.state.doneloading) {
			return (
				<br/>
			)
		}
		else {
			if (this.state.width <= 500) {
				if (!this.state.validToken) {
					return (
						<div style={{padding: 25, backgroundColor: "#eee"}}>
							<h1>This sign up link has expired</h1>
						</div>
					)
				}
				else {
					return (
						<div style={{padding: 25, backgroundColor: "#eee"}}>
								<form onSubmit={this.onSubmit}>
									<h1>yay4me</h1>
									<p>
									User sign up
									</p>
									{this.state.signupError &&
										<p><font color="red">{this.state.signupErrorMessage}</font></p>
									}
									<div className="form-group"> 
										<label>Email: </label>
										<input  type="email"
											className="form-control"
											value={this.state.email}
											onChange={this.onChangeEmail}
										/>
									</div>
									<div className="form-group">
										<label>Password: </label>
										<input 
											type="password" 
											className="form-control"
											value={this.state.password}
											onChange={this.onChangePassword}
										/>
									</div>
									<div className="form-group">
										<label>Confirm Password: </label>
										<input 
											type="password" 
											className="form-control"
											value={this.state.confirmPassword}
											onChange={this.onChangeConfirmPassword}
										/>
									</div>
									<div className="form-group">
										<input type="submit" value="Sign up" className="btn btn-primary" />
									</div>
								</form>
						</div>
					)
				}
			}
			else {
				if (!this.state.validToken) {
					return (
						<SUSIWrapper>
							<h1>This sign up link has expired</h1>
						</SUSIWrapper>
					)
				}
				else {

					return (
						<SUSIWrapper>
							<div style={{marginTop: 10}}>
								<form onSubmit={this.onSubmit}>
									<h1>yay4me</h1>
									<p>
									User sign up
									</p>
									{this.state.signupError &&
										<p><font color="red">{this.state.signupErrorMessage}</font></p>
									}
									<div className="form-group"> 
										<label>Email: </label>
										<input  type="email"
											className="form-control"
											value={this.state.email}
											onChange={this.onChangeEmail}
										/>
									</div>
									<div className="form-group">
										<label>Password: </label>
										<input 
											type="password" 
											className="form-control"
											value={this.state.password}
											onChange={this.onChangePassword}
										/>
									</div>
									<div className="form-group">
										<label>Confirm Password: </label>
										<input 
											type="password" 
											className="form-control"
											value={this.state.confirmPassword}
											onChange={this.onChangeConfirmPassword}
										/>
									</div>
									<div className="form-group">
										<input type="submit" value="Sign up" className="btn btn-primary" />
									</div>
								</form>
							</div>
						</SUSIWrapper>
					)
				}
			}
		}
    }
}
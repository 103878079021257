import React, { Component } from 'react';
import axios from 'axios';
import { appConfig } from "../utils/config.js";
import { getSessionId } from "../utils/sessionUtil.js";
import SIWrapper from "../layouts/signedin.layout";
import DatePicker from "react-datepicker";
import { Dropdown, DropdownButton, Modal, Button, Form } from 'react-bootstrap';

import arrowRight from "../images/arrow-right.png";
import arrowLeft from "../images/arrow-left.png";
import runActivity from "../images/run.png";
import bikeActivity from "../images/bike.png";
import swimActivity from "../images/swim.png";
import walkActivity from "../images/walk.png";
import runwalkActivity from "../images/runwalk.png";
import strengthActivity from "../images/strength.png";
import yogaActivity from "../images/yoga.png";
import stretchActivity from "../images/stretch.png";
import readActivity from "../images/read.png";
import watchActivity from "../images/watch.png";
import listenActivity from "../images/listen.png";
import journalActivity from "../images/journal.png";
import meditateActivity from "../images/meditate.png";
import otherActivity from "../images/other.png";
import completed from "../images/completed.png";

import "react-datepicker/dist/react-datepicker.css";

export default class Calendar extends Component {

	constructor(props) {
		super(props);
		let today = new Date();
		this.state = {
			name: '',
			startYear: today.getFullYear(),
			startMonth: today.getMonth()+1,
			startDate: today.getDate(),
			weekOffset: 0,
			year: 0,
			monthName: '',
			activityEnum: '',
			activityTitle: '',
            activityDescription: '',
            activityDurationHours: '',
            activityDurationMinutes: '',
            activityYear: 0,
            activityMonth: 0,
            activityDate: 0,
            activityDueDuringWeek: 0,
            activityId: 0,
            calendarUserId: '',
			error: false,
			doneloading: false,
			activityChooseShow: false,
			workoutModalShow: false,
			taskModalShow: false,
			confirmDeleteModalShow: false,
			activityCompleted: false,
			createSeriesModalShow: false,
			coachedUser: this.props.match.params.usr,
			coachedUserName: '',
			seriesStartDate: null,
			seriesEndDate: null,
			seriesName: '',
			seriesToInsertName: '',
			insertSeriesModalShow: false,
			duplicateSeriesName: false,
			seriesNames: [],
		}
	}

	componentDidMount = (e) => {
		this.retrieveSeriesNames(() => {
			this.retrieveData(() => {
				this.setState({
					doneloading: true
				});
			});
		});
	}

	retrieveData = (callback) => {
		// Validate the session
		let sessionId = getSessionId();
		// calculate the dates
		let refDate = new Date(this.state.startYear+'/'+this.state.startMonth+'/'+this.state.startDate);
		refDate.setDate(refDate.getDate()+(this.state.weekOffset*7));
		let year = refDate.getFullYear();
		let month = refDate.getMonth()+1;
		let date = refDate.getDate();
		let refDate2 = new Date(year+'/'+month+'/1');
		let firstDayOfWeek = refDate2.getDay()-2;
		let weekNumber = Math.floor((firstDayOfWeek+date)/7)+1;
		// retrieve the data
		axios.post(appConfig.apihost+'/api/get-calendar-data/', {sessionId: sessionId, userId: this.state.coachedUser, year: year, month: month, weekNumber: weekNumber, weeks: 4})
		.then(response => {
			if (response.data.invalidSession) {
		        this.props.history.push("/logout");
			}
			else if (response.data.success) {
				this.setState({
					name: response.data.session.user_name,
					year: response.data.year,
					monthName: response.data.monthName,
					calendarUserId: response.data.userId,
					calendarData: response.data.calendarData,
					coachedUserName: response.data.coachedUserName
				});
			}
			else {
				this.setState({
					error: true
				});
			}
			callback();
		})
		.catch(error => {
			console.log('error',error);
			callback();
		});
	}

	retrieveSeriesNames = (callback) => {
		if (!this.state.coachedUser) callback();
		else {
			// Validate the session
			let sessionId = getSessionId();
			// retrieve the data
			axios.post(appConfig.apihost+'/api/get-series-names/', {sessionId: sessionId})
			.then(response => {
				if (response.data.success) {
					this.setState({
						seriesNames: response.data.seriesNames,
					});
				}
				callback();
			})
			.catch(error => {
				console.log('error',error);
				callback();
			});
		}
	}

	addNewActivity = (e, year, month, date, wosd) => {
		let activityDueDuringWeek = 0;
		if (wosd) {
			date = wosd;
			activityDueDuringWeek = 1
		}
		this.setState({
			activityYear: year,
			activityMonth: month,
			activityDate: date,
			activityDueDuringWeek: activityDueDuringWeek,
			activityTitle: '',
            activityDurationHours: '',
            activityDurationMinutes: '',
            activityDescription: '', 
            modalAddOrUpdate: 'add',
            activityCompleted: false
		});
		this.handleActivityChooseShow();
	}

	handleActivityChooseShow = () => {
		this.setState({ activityChooseShow: true });
	}

	handleActivityChooseHide = () => {
		this.setState({ activityChooseShow: false });
	}

	handleWorkoutModalShow = () => {
		this.setState({ workoutModalShow: true });
	}

	handleWorkoutModalHide = () => {
		this.setState({ workoutModalShow: false });
	}

	handleTaskModalShow = () => {
		this.setState({ taskModalShow: true });
	}

	handleTaskModalHide = () => {
		this.setState({ taskModalShow: false });
	}

	handleConfirmDeleteModalShow = (aid) => {
		this.setState({ 
			confirmDeleteModalShow: true,
			activityId: aid,
		});
	}

	handleConfirmDeleteModalHide = () => {
		this.setState({ confirmDeleteModalShow: false });
	}

	handleCreateSeriesModalShow = () => {
		this.setState({ 
			createSeriesModalShow: true,
			duplicateSeriesName: false
		});
	}

	handleCreateSeries = (replace) => {
		// Validate the session
		let sessionId = getSessionId();
		// send the data
		let seriesData = {
			sessionId: sessionId,
			userId: this.state.coachedUser,
			startDate: this.state.seriesStartDate,
			endDate: this.state.seriesEndDate,
			seriesName: this.state.seriesName,
			replace: replace
		}
		axios.post(appConfig.apihost+'/api/create-series/', seriesData)
		.then(response => {
			if (response.data.invalidSession) {
		        this.props.history.push("/logout");
			}
			else if (response.data.duplicateSeriesName) {
				this.setState({
					duplicateSeriesName: true,
				});
			}
			else {
				this.retrieveSeriesNames(() => {
					this.handleCreateSeriesModalHide();
				})
			}
		})
		.catch(error => {
			console.log('error',error);
		});
	}

	handleCreateSeriesModalHide = () => {
		this.setState({ createSeriesModalShow: false });
	}

	handleInsertSeriesModalShow = () => {
		this.setState({ 
			seriesToInsertName: this.state.seriesNames[0],
			insertSeriesModalShow: true
		});
	}

	handleInsertSeriesModalHide = () => {
		this.setState({ insertSeriesModalShow: false });
	}
	
	handleInsertSeries = () => {
		// Validate the session
		let sessionId = getSessionId();
		// send the data
		let seriesData = {
			sessionId: sessionId,
			userId: this.state.coachedUser,
			startDate: this.state.seriesStartDate,
			seriesName: this.state.seriesToInsertName,
		}
		axios.post(appConfig.apihost+'/api/insert-series/', seriesData)
		.then(response => {
			if (response.data.invalidSession) {
		        this.props.history.push("/logout");
			}
			else if (response.data.success) {
				this.retrieveData(() => {})
			}
		})
		.catch(error => {
			console.log('error',error);
		});
		this.handleInsertSeriesModalHide();
	}
	
	switchToWorkoutModal = () => {
		// set default dropdown type for workout
		this.setState({
			activityEnum: 'walk',
		});
		this.handleActivityChooseHide();		
		this.handleWorkoutModalShow();
	}
 
	switchToTaskModal = () => {
		// set default dropdown type for task
		this.setState({
			activityEnum: 'read',
		});
		this.handleActivityChooseHide();
		this.handleTaskModalShow();
	}
 
	onCompletedChange = (e) => {
        this.setState({
            activityCompleted: !this.state.activityCompleted,
            activityMarkedCompleted: !this.state.activityCompleted
        });
	}

	onActivityEnumChange = (e) => {
        this.setState({
            activityEnum: e.target.value
        });
	}

	onActivityTitleChange = (e) => {
        this.setState({
            activityTitle: e.target.value
        });
	}

	onActivityDurationHoursChange = (e) => {
        this.setState({
            activityDurationHours: e.target.value
        });
	}

	onActivityDurationMinutesChange = (e) => {
        this.setState({
            activityDurationMinutes: e.target.value
        });
	}

	onActivityDescriptionChange = (e) => {
        this.setState({
            activityDescription: e.target.value
        });
	}

	addNewWorkout = () => {
		this.handleWorkoutModalHide();
		// Validate the session
		let sessionId = getSessionId();
		// send the data
		let completed = 0;
		if (this.state.activityCompleted) completed = 1;
		let workoutData = {
			sessionId: sessionId,
			type: 'workout',
            activityYear: this.state.activityYear,
            activityMonth: this.state.activityMonth,
            activityDate: this.state.activityDate,
            activityDueDuringWeek: this.state.activityDueDuringWeek,
            calendarUserId: this.state.calendarUserId,
			activityEnum: this.state.activityEnum,
			activityTitle: this.state.activityTitle,
            activityDurationHours: this.state.activityDurationHours,
            activityDurationMinutes: this.state.activityDurationMinutes,
            activityDescription: this.state.activityDescription,
            activityCompleted: completed
		}
		axios.post(appConfig.apihost+'/api/add-activity/', workoutData)
		.then(response => {
			if (response.data.invalidSession) {
		        this.props.history.push("/logout");
			}
			else if (response.data.success) {
				this.retrieveData(() => {})
			}
			else {
				this.setState({
					error: true
				});
			}
		})
		.catch(error => {
			console.log('error',error);
		});
	}
 
	updateWorkout = () => {
		this.handleWorkoutModalHide();
		// Validate the session
		let sessionId = getSessionId();
		// send the data
		let completed = 0;
		if (this.state.activityCompleted) completed = 1;
		let workoutData = {
			sessionId: sessionId,
			type: 'workout',
			activityId: this.state.activityId,
            calendarUserId: this.state.calendarUserId,
			activityEnum: this.state.activityEnum,
			activityTitle: this.state.activityTitle,
            activityDurationHours: this.state.activityDurationHours,
            activityDurationMinutes: this.state.activityDurationMinutes,
            activityDescription: this.state.activityDescription,
            activityCompleted: completed,
            activityMarkedCompleted: this.state.activityMarkedCompleted,
            updatedByCoach: this.state.coachedUser !== undefined
		}
		axios.post(appConfig.apihost+'/api/update-activity/', workoutData)
		.then(response => {
			if (response.data.invalidSession) {
		        this.props.history.push("/logout");
			}
			else if (response.data.success) {
				this.retrieveData(() => {})
			}
			else {
				this.setState({
					error: true
				});
			}
		})
		.catch(error => {
			console.log('error',error);
		});
	}

 	deleteActivity = () => {
		this.handleConfirmDeleteModalHide();
		// Validate the session
		let sessionId = getSessionId();
		// send the data
		let activityData = {
			sessionId: sessionId,
            activityId: this.state.activityId
		}
		axios.post(appConfig.apihost+'/api/delete-activity/', activityData)
		.then(response => {
			if (response.data.invalidSession) {
		        this.props.history.push("/logout");
			}
			else if (response.data.success) {
				this.retrieveData(() => {})
			}
			else {
				this.setState({
					error: true
				});
			}
		})
		.catch(error => {
			console.log('error',error);
		});
	}

	addNewTask = () => {
		this.handleTaskModalHide();
		// Validate the session
		let sessionId = getSessionId();
		// send the data
		let completed = 0;
		if (this.state.activityCompleted) completed = 1;
		let taskData = {
			sessionId: sessionId,
			type: 'task',
            activityYear: this.state.activityYear,
            activityMonth: this.state.activityMonth,
            activityDate: this.state.activityDate,
            activityDueDuringWeek: this.state.activityDueDuringWeek,
            calendarUserId: this.state.calendarUserId,
			activityEnum: this.state.activityEnum,
			activityTitle: this.state.activityTitle,
            activityDescription: this.state.activityDescription,
            activityCompleted: completed
		}
		axios.post(appConfig.apihost+'/api/add-activity/', taskData)
		.then(response => {
			if (response.data.invalidSession) {
		        this.props.history.push("/logout");
			}
			else if (response.data.success) {
				this.retrieveData(() => {})
			}
			else {
				this.setState({
					error: true
				});
			}
		})
		.catch(error => {
			console.log('error',error);
		});
	}

	updateTask = () => {
		this.handleTaskModalHide();
		// Validate the session
		let sessionId = getSessionId();
		// send the data
		let completed = 0;
		if (this.state.activityCompleted) completed = 1;
		let taskData = {
			sessionId: sessionId,
			type: 'task',
			activityId: this.state.activityId,
            calendarUserId: this.state.calendarUserId,
			activityEnum: this.state.activityEnum,
			activityTitle: this.state.activityTitle,
            activityDescription: this.state.activityDescription,
            activityCompleted: completed,
			activityMarkedCompleted: this.state.activityMarkedCompleted,
            updatedByCoach: this.state.coachedUser !== undefined
		}
		axios.post(appConfig.apihost+'/api/update-activity/', taskData)
		.then(response => {
			if (response.data.invalidSession) {
		        this.props.history.push("/logout");
			}
			else if (response.data.success) {
				this.retrieveData(() => {})
			}
			else {
				this.setState({
					error: true
				});
			}
		})
		.catch(error => {
			console.log('error',error);
		});
	}

 	onArrowClickLeft = (e) => {
		// eslint-disable-next-line
       	this.state.weekOffset -= 1;
		this.retrieveData(() => {});
 	}

    onArrowClickRight = (e) => {
		// eslint-disable-next-line
		this.state.weekOffset += 1;
		this.retrieveData(() => {});
 	}

    onReturnClick = (e) => {
	    // eslint-disable-next-line
       	this.state.weekOffset = 0;
		this.retrieveData(() => {});
 	}

	handleSeriesStartDateChange = (date) => {
		this.setState({
			seriesStartDate: date
		});
	}

	handleSeriesEndDateChange = (date) => {
		this.setState({
			seriesEndDate: date
		});
	}

	onSeriesNameChange = (e) => {
        this.setState({
            seriesName: e.target.value
        });
	}

    onDragStart = (e, id) => {
		e.dataTransfer.setData("id", id);
		// make all addbox elements hidden to mouse events
		let allAddBoxes = document.getElementsByName("addbox");
		for (let i = 0; i < allAddBoxes.length; i++) {
			allAddBoxes[i].style.pointerEvents = "none";
		}
 	}

    onDragEnd = (e) => {
		// make all addbox elements hidden to mouse events
		let allAddBoxes = document.getElementsByName("addbox");
		for (let i = 0; i < allAddBoxes.length; i++) {
			allAddBoxes[i].style.removeProperty("pointer-events");
		}
 	}

    onDragEnter = (e) => {
 		e.target.style.border = "1px solid #55f";
		if (e.target.id === 'dropboxspacer') {
			e.target.style.height = "15px";
		}
 	}

    onDragOver = (e) => {
        e.preventDefault();
    }

    onDragLeave = (e) => {
		e.target.style.removeProperty("border");
		e.target.style.removeProperty("height");
    }

    onDrop = (e, year, month, date, wosd, order) => {
        e.preventDefault();
		e.target.style.removeProperty("border");
		e.target.style.removeProperty("height");
		let aId = e.dataTransfer.getData("id");
		// move the activity
		// Validate the session
		let sessionId = getSessionId();
		let activityDueDuringWeek = 0;
		if (wosd) {
			date = wosd;
			activityDueDuringWeek = 1;
		}
		// send the data
		let activityData = {
			sessionId: sessionId,
			activityId: aId,
			activityYear: year,
            activityMonth: month,
            activityDate: date,
			activityDueDuringWeek: activityDueDuringWeek,
            activityOrder: order,
            calendarUserId: this.state.calendarUserId,
		}
		axios.post(appConfig.apihost+'/api/move-activity/', activityData)
		.then(response => {
			if (response.data.success) {
				this.retrieveData(() => {})
			}
		})
		.catch(error => {
			console.log('error',error);
		});
    }

    editActivity = (e, aId) => {
		if (e.target.name === 'descriptionTextLink') return;
		// Validate the session
		let sessionId = getSessionId();
		// send the data
		let activityData = {
			sessionId: sessionId,
            activityId: aId
		}
		axios.post(appConfig.apihost+'/api/get-activity/', activityData)
		.then(response => {
			if (response.data.invalidSession) {
		        this.props.history.push("/logout");
			}
			else if (response.data.success) {
				let which;
				if (response.data.type === 'workout') which = response.data.workout;
				else which = response.data.task;
				this.setState({
					activityEnum: which,
					activityTitle: response.data.title,
					activityDurationHours: response.data.hours,
					activityDurationMinutes: response.data.minutes,
					activityDescription: response.data.description,
					activityId: aId,
					activityCompleted: (response.data.completed === 1),
					modalAddOrUpdate: 'update'
				});
				if (response.data.type === 'workout') this.handleWorkoutModalShow();
				else this.handleTaskModalShow();
			}
		})
		.catch(error => {
			console.log('error',error);
		});
 	}

    cloneActivity = (aId) => {
		// Validate the session
		let sessionId = getSessionId();
		// send the data
		let activityData = {
			sessionId: sessionId,
            activityId: aId,
            calendarUserId: this.state.calendarUserId

		}
		axios.post(appConfig.apihost+'/api/clone-activity/', activityData)
		.then(response => {
			if (response.data.invalidSession) {
		        this.props.history.push("/logout");
			}
			else if (response.data.success) {
				this.retrieveData(() => {})
			}
			else {
				this.setState({
					error: true
				});
			}
		})
		.catch(error => {
			console.log('error',error);
		});
 	}

	menuClick = (e, which, aid) => {
		if (which) {
			if (which === 'delete') {
				this.handleConfirmDeleteModalShow(aid);
			}
			else if (which === 'clone') {
				this.cloneActivity(aid);
			}
		}
		e.stopPropagation();
	}

	seriesNameClick = (seriesName) => {
		this.setState({
			seriesToInsertName: seriesName
		});
	}

    activityDisplay = (currentDay) => {
        return currentDay.activities.map((currentActivity, i) => {
			let is;
			let duration = '';
			let bgc;
			if (currentActivity.activity_type === 'workout') {
				if (currentActivity.workout === 'run') is = runActivity;
				else if (currentActivity.workout === 'bike') is = bikeActivity;
				else if (currentActivity.workout === 'swim') is = swimActivity;
				else if (currentActivity.workout === 'walk') is = walkActivity;
				else if (currentActivity.workout === 'rn/wlk') is = runwalkActivity;
				else if (currentActivity.workout === 'strength') is = strengthActivity;
				else if (currentActivity.workout === 'yoga') is = yogaActivity;
				else if (currentActivity.workout === 'stretch') is = stretchActivity;
				if (currentActivity.duration_hours) duration = currentActivity.duration_hours + ':';
				else duration = '0:';
				let minutesStr = '0' + currentActivity.duration_minutes;
				minutesStr = minutesStr.substring(minutesStr.length-2, minutesStr.length);
				duration = duration + minutesStr;
				bgc = "#88DD88";
				if (currentActivity.gen_program_id > 0) bgc = "#EEFFEE";
			}
			else {
				if (currentActivity.task === 'read') is = readActivity;
				else if (currentActivity.task === 'watch') is = watchActivity;
				else if (currentActivity.task === 'listen') is = listenActivity;
				else if (currentActivity.task === 'journal') is = journalActivity;
				else if (currentActivity.task === 'meditate') is = meditateActivity;
				else if (currentActivity.task === 'other') is = otherActivity;
				bgc = "#88DDDD";
				if (currentActivity.gen_program_id > 0) bgc = "#EEFFFF";
			}

			return (
				<section key={i} >
				<div id="dropboxspacer" className="dropbox-margins dropbox-height" onDragOver={this.onDragOver} onDragEnter={this.onDragEnter} onDragLeave={this.onDragLeave} onDrop={(e) => this.onDrop(e, currentDay.year, currentDay.month, currentDay.date, currentDay.weekOfStartingDate, i)}>
				</div>
				{ currentActivity.gen_program_id === 0 ?
					<div className="activityBox" onDragStart={(e) => this.onDragStart(e, currentActivity.id)} onDragEnd={this.onDragEnd} draggable style={{background: bgc}} onClick={(e) => this.editActivity(e, currentActivity.id)}>
						{(currentActivity.completed === 1) && 
							<div className="activityBoxDim">
								<img alt="" style={{display: "block", marginLeft: "auto", marginRight: "auto", paddingTop: "15px", width: "60px"}} src={completed} />
							</div>
						}
						<img alt="" height="30" src={is}/>
						<DropdownButton className="outer" size="sm" variant="secondary" title="" onClick={(e) => this.menuClick(e)}>
							<Dropdown.Item style={{fontSize: "75%", padding: "4px 0px 0px 5px"}} onClick={(e) => this.menuClick(e, 'clone', currentActivity.id)}>Clone</Dropdown.Item>
							<Dropdown.Item style={{fontSize: "75%", padding: "4px 0px 0px 5px"}} onClick={(e) => this.menuClick(e, 'delete', currentActivity.id)}>Delete</Dropdown.Item>
						</DropdownButton>
						<br/><span className="titleText">{currentActivity.title}</span>
						{(currentActivity.activity_type === 'workout') && <><br/><span className="durationText">{duration}</span></>}
						<br/><span className="descriptionText">{this.textDisplay(currentActivity.description)}</span>
					</div>
				:
					<div className="activityBox" style={{background: bgc}}>
						{(currentActivity.completed === 1) && 
							<div className="activityBoxDim">
								<img alt="" style={{display: "block", marginLeft: "auto", marginRight: "auto", paddingTop: "15px", width: "60px"}} src={completed} />
							</div>
						}
						<img alt="" height="30" src={is}/>
						<br/><span className="titleText">{currentActivity.title}</span>
						{(currentActivity.activity_type === 'workout') && <><br/><span className="durationText">{duration}</span></>}
						<br/><span className="descriptionText">{this.textDisplay(currentActivity.description)}</span>
					</div>
				}
				</section>
			)
	    });
	}

	textDisplay = (text) => {
		return text.split('\n').map((line, i) => {
			line = line.replace(/(^|\s)(htt(?:p|ps):\/\/www.youtube.com\S+)/g, '$1|^|^:W:^$2|^|');
			line = line.replace(/(^|\s)(htt(?:p|ps):\/\/youtube.com\S+)/g, '$1|^|^:W:^$2|^|');
			line = line.replace(/(^|\s)(htt(?:p|ps):\/\/youtu.be\S+)/g, '$1|^|^:W:^$2|^|');
			line = line.replace(/(^|\s)(htt(?:p|ps):\/\/\S+)/g, '$1|^|^:L:^$2|^|');
			return (
				<span key={i}>
					{ this.lineDisplay(line) }
					<br/>
				</span>
			)
		});
	}

	lineDisplay = (line) => {
		return line.split('|^|').map((line2, i) => {
			if (line2.indexOf('^:W:^') === 0) {
				line2 = line2.substring(5);
				return (<a key={i} name="descriptionTextLink" href={line2} target="_blank">Watch</a>);
			}
			else if (line2.indexOf('^:L:^') === 0) {
				line2 = line2.substring(5);
				return (<a key={i} name="descriptionTextLink" href={line2} target="_blank">Link</a>);
			}
			else return (<React.Fragment key={i}>{line2}</React.Fragment>)
		});
	}

    dayDisplay = (currentWeek) => {
        return currentWeek.map((currentDay, i) => {
        	let bgColor;
        	if (currentDay.year === this.state.startYear && currentDay.month === this.state.startMonth && currentDay.date === this.state.startDate) bgColor = "#bbd";
        	else bgColor = "#ddf";
        	return  (
            	<td key={i} style={{minWidth: "100px", maxWidth: "150px"}}>
				<section style={{height: "100%", display: "flex",  flexFlow: "column"}}>
					<div style={{backgroundColor: bgColor}}>
					{(currentDay.weekOfStartingDate) ? 'Week' : currentDay.date}
					</div>
					{(currentDay.activities && currentDay.activities.length > 0) && this.activityDisplay(currentDay) }
					<div className="dropbox-margins" style={{flex: "1"}} onDragOver={this.onDragOver} onDragEnter={this.onDragEnter} onDragLeave={this.onDragLeave} onDrop={(e) => this.onDrop(e, currentDay.year, currentDay.month, currentDay.date, currentDay.weekOfStartingDate, -1)}>           	
						<div name="addbox" className="addActivityBox" onClick={(e) => this.addNewActivity(e, currentDay.year, currentDay.month, currentDay.date, currentDay.weekOfStartingDate)}>+</div>            	
					</div>
				</section>
            	</td>
            );
	    });
    }

    weekDisplay = () => {
        return this.state.calendarData.map((currentWeek, i) => {
            return  ( <tr valign="top" key={i}>{ this.dayDisplay(currentWeek) }</tr> );
	    });
    }
    
    seriesNameDropdownItems = () => {
        return this.state.seriesNames.map((name, i) => {
            return  ( <Dropdown.Item key={i} onClick={() => this.seriesNameClick(name)}>{name}</Dropdown.Item> );
	    });
    }

	render() {
		if (!this.state.doneloading) {
			return (
				<br/>
			)
		}
		else {
			return (
				<SIWrapper name={this.state.name}>
					<div style={{padding: "0px 0px 0px 5px"}}>
					<table><tbody><tr height="35">
						<td width="140">{this.state.monthName}&nbsp;&nbsp;{this.state.year}</td>
						<td style={{padding: "5px 8px 0px 0px"}}><input type="button" style={{height: "20px", width: "20px", background: "url("+arrowLeft+")", border: "none", outline: "0", backgroundSize: "contain"}} onClick={this.onArrowClickLeft}/></td>
						<td style={{padding: "5px 0px 0px 0px"}}><input type="button" style={{height: "20px", width: "20px", background: "url("+arrowRight+")", border: "none", outline: "0", backgroundSize: "contain"}} onClick={this.onArrowClickRight}/></td>
						{(this.state.weekOffset !== 0) ? <td width="200px" style={{padding: "0px 0px 2px 30px"}}><button style={{fontSize: "50%"}} onClick={this.onReturnClick}>Return to Current Week</button></td> : <td width="200px"></td>}
						{this.state.coachedUser && 
							<><td width="50px"></td><td>Calendar for <b>{this.state.coachedUserName}</b></td>
							<td width="60px"></td>
							<td><button style={{fontSize: "50%"}} onClick={this.handleCreateSeriesModalShow}>Create Series</button></td>
							<td width="20px"></td>
							{this.state.seriesNames.length > 0 && 
								<td><button style={{fontSize: "50%"}} onClick={this.handleInsertSeriesModalShow}>Insert Series</button></td>
							}
							</>
						}
						</tr></tbody></table>
					<table width="99%" height="100%" style={{backgroundColor: "#eee"}}>
						<thead style={{fontSize: "12px", backgroundColor: "#ddd", borderTop: "1px solid gray", borderBottom: "1px solid gray"}}>
							<tr><th></th><th>Monday</th><th>Tuesday</th><th>Wednesday</th><th>Thursday</th><th>Friday</th><th>Saturday</th><th>Sunday</th></tr>
						</thead>
						<tbody>
							{ this.weekDisplay() }
						</tbody>
					</table>
					</div>

     				<Modal show={this.state.activityChooseShow}
          				dialogClassName="modal-90w"
          				aria-labelledby="example-custom-modal-styling-title">
					<Modal.Header>
						<Modal.Title id="example-custom-modal-styling-title">Add Activity</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Button variant="info" onClick={this.switchToWorkoutModal}>Workout</Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						<Button variant="info" onClick={this.switchToTaskModal}>Task</Button>
          			</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={this.handleActivityChooseHide}>Cancel</Button>
					</Modal.Footer>
					</Modal>

     				<Modal show={this.state.workoutModalShow}
          				dialogClassName="modal-90w"
          				aria-labelledby="example-custom-modal-styling-title">
					<Modal.Header>
						<Modal.Title id="example-custom-modal-styling-title">Workout</Modal.Title>
						<Form.Group controlId="formBasicChecbox">
							<Form.Check style={{marginTop: "8px", marginRight: "10px"}} type="checkbox" label="Completed" checked={this.state.activityCompleted} onChange={this.onCompletedChange}/>
						</Form.Group>						
					</Modal.Header>
					<Modal.Body>
						<table><tbody><tr><td>Type:&nbsp;</td><td>
						<select value={this.state.activityEnum} className="browser-default custom-select" onChange={this.onActivityEnumChange}>
						  <option value="walk">Walk</option>
						  <option value="run">Run</option>
						  <option value="rn/wlk">Run/Walk</option>
						  <option value="bike">Bike</option>
						  <option value="swim">Swim</option>
						  <option value="strength">Strength</option>
						  <option value="yoga">Yoga</option>
						  <option value="stretch">Stretch</option>
						</select>
						</td></tr></tbody></table>
						<table><tbody>
						<tr height="10px"></tr>
						<tr><td>Title:&nbsp;&nbsp;</td><td>
							<input style={{width: "414px"}} type="text" className="form-control"
								value={this.state.activityTitle} onChange={this.onActivityTitleChange} />
						</td></tr>
						</tbody></table>
						<table><tbody>
							<tr height="10px"></tr>
							<tr><td>Duration</td><td width="30"></td><td>Hours:&nbsp;</td><td>
								<input style={{width: "80px"}} type="number" className="form-control" value={this.state.activityDurationHours} onChange={this.onActivityDurationHoursChange} />							
							</td><td width="15"></td><td>Minutes:&nbsp;</td><td>
								<input style={{width: "80px"}} type="number" className="form-control" value={this.state.activityDurationMinutes} onChange={this.onActivityDurationMinutesChange} />						
							</td></tr>
							<tr height="10px"></tr>
						</tbody></table>
						Description:
						<textarea rows="5" cols="55" style={{width: "100%"}} value={this.state.activityDescription} onChange={this.onActivityDescriptionChange} >
						</textarea>
          			</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={this.handleWorkoutModalHide}>Cancel</Button>
		            	{(this.state.modalAddOrUpdate === 'add') ? <Button variant="primary" onClick={this.addNewWorkout}>Add Workout</Button> : <Button variant="primary" onClick={this.updateWorkout}>Update Workout</Button>}
					</Modal.Footer>
					</Modal>
					
     				<Modal show={this.state.taskModalShow}
          				dialogClassName="modal-90w"
          				aria-labelledby="example-custom-modal-styling-title">
					<Modal.Header>
						<Modal.Title id="example-custom-modal-styling-title">Task</Modal.Title>
						<Form.Group controlId="formBasicChecbox">
							<Form.Check style={{marginTop: "8px", marginRight: "10px"}} type="checkbox" label="Completed" checked={this.state.activityCompleted} onChange={this.onCompletedChange}/>
						</Form.Group>						
					</Modal.Header>
					<Modal.Body>
						<table><tbody><tr><td>Type:&nbsp;</td><td>
						<select value={this.state.activityEnum} className="browser-default custom-select" onChange={this.onActivityEnumChange}>
						  <option value="read">Read</option>
						  <option value="watch">Watch</option>
						  <option value="listen">Listen</option>
						  <option value="journal">Journal</option>
						  <option value="meditate">Meditate</option>
						  <option value="other">Other</option>
						</select>
						</td></tr></tbody></table>
						<table><tbody>
						<tr height="10px"></tr>
						<tr><td>Title:&nbsp;&nbsp;</td><td>
							<input style={{width: "414px"}} type="text" className="form-control"
								value={this.state.activityTitle} onChange={this.onActivityTitleChange} />
						</td></tr>
						<tr height="10px"></tr>
						</tbody></table>
						Description:
						<textarea rows="5" cols="55" style={{width: "100%"}} value={this.state.activityDescription} onChange={this.onActivityDescriptionChange} >
						</textarea>
          			</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={this.handleTaskModalHide}>Cancel</Button>
		            	{(this.state.modalAddOrUpdate === 'add') ? <Button variant="primary" onClick={this.addNewTask}>Add Task</Button> : <Button variant="primary" onClick={this.updateTask}>Update Task</Button>}
					</Modal.Footer>
					</Modal>

     				<Modal show={this.state.confirmDeleteModalShow}
          				dialogClassName="modal-90w"
          				aria-labelledby="example-custom-modal-styling-title">
					<Modal.Header>
						<Modal.Title id="example-custom-modal-styling-title">Delete Activity?</Modal.Title>
					</Modal.Header>
					<Modal.Footer>
						<Button variant="secondary" onClick={this.handleConfirmDeleteModalHide}>Cancel</Button>
						<Button variant="primary" onClick={this.deleteActivity}>Delete</Button>
					</Modal.Footer>
					</Modal>

     				<Modal show={this.state.createSeriesModalShow}
          				dialogClassName="modal-90w"
          				aria-labelledby="example-custom-modal-styling-title">
					<Modal.Header>
						<Modal.Title id="example-custom-modal-styling-title">Create Series</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<p>Create series from activities between these date ranges</p>
						<table cellPadding="10px">
						<tbody>
						<tr><td>
						<label>Start Date: </label><br/>
						<DatePicker
							selected={this.state.seriesStartDate}
							onChange={this.handleSeriesStartDateChange}
						/>
						</td><td>
						<label>End Date: </label><br/>
						<DatePicker
							selected={this.state.seriesEndDate}
							onChange={this.handleSeriesEndDateChange}
						/>
						</td></tr>
						</tbody>
						</table>
						<div style={{padding: "10px 50px 0px 10px"}} className="form-group">
							<label>Series Name: </label>
							<input 
								type="text" 
								className="form-control"
								value={this.state.seriesName}
								onChange={this.onSeriesNameChange}
							/>
						</div>
						{(this.state.duplicateSeriesName) && 
							<p><font color="red">Series name in use</font>&nbsp;&nbsp;&nbsp;&nbsp;<Button size="sm" variant="primary" onClick={() => this.handleCreateSeries(true)}>Replace</Button></p>
						}
          			</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={this.handleCreateSeriesModalHide}>Cancel</Button>&nbsp;&nbsp;
						<Button variant="primary" onClick={() => this.handleCreateSeries(false)}>Create</Button>
					</Modal.Footer>
					</Modal>

     				<Modal show={this.state.insertSeriesModalShow}
          				dialogClassName="modal-90w"
          				aria-labelledby="example-custom-modal-styling-title">
					<Modal.Header>
						<Modal.Title id="example-custom-modal-styling-title">Insert Series</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<table><tbody><tr><td>
						<DropdownButton variant="secondary" title="Series">
							{ this.seriesNameDropdownItems() }
						</DropdownButton>
						</td><td>&nbsp;</td><td>{this.state.seriesToInsertName}</td>
						</tr></tbody></table>
						<br/>
						<label>Start Date: </label><br/>
						<DatePicker
							selected={this.state.seriesStartDate}
							onChange={this.handleSeriesStartDateChange}
						/>
          			</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={this.handleInsertSeriesModalHide}>Cancel</Button>&nbsp;&nbsp;
						<Button variant="primary" onClick={this.handleInsertSeries}>Insert</Button>
					</Modal.Footer>
					</Modal>

				</SIWrapper>
			)
		}
    }
}
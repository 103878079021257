import React, { Component } from 'react';
import axios from 'axios';
import { appConfig } from "../utils/config.js";
import SUSIWrapper from "../layouts/signupsignin.layout";

export default class ResetPassword extends Component {

	constructor(props) {
		super(props);
	
		this.state = {
			email: '',
			password: '',
			confirmPassword: '',
			resetError: false,
			resetErrorMessage: '',
			validToken: false,
			doneloading: false
		}
	}

	componentDidMount = (e) => {
		// Validate token
		axios.post(appConfig.apihost+'/api/reset-password-check/', {token: this.props.match.params.tkn})
		.then(response => {
			this.setState({
				validToken: response.data.valid,
				email: response.data.email,
				doneloading: true
			});
		})
		.catch(error => {
			console.log('error',error)
		});
	}

    onChangePassword = (e) => {
        this.setState({
            password: e.target.value
        });
    }

    onChangeConfirmPassword = (e) => {
        this.setState({
            confirmPassword: e.target.value
        });
    }

	onSubmit = (e) => {
		e.preventDefault();
 		const resetPasswordValues = {
            email: this.state.email,
            password: this.state.password,
            confirmPassword: this.state.confirmPassword,
            token: this.props.match.params.tkn
        };
        axios.post(appConfig.apihost+'/api/reset-password', resetPasswordValues)
		.then(response => {
			if (response.data.success) {
		        this.props.history.push('/');
			}
			else {
				this.setState({	
					resetError: true,
					resetErrorMessage: response.data.message
				});
			}
		})
		.catch(error => {
			console.log('error:',error);
		});
	}

	render() {
		if (!this.state.doneloading) {
			return (
				<br/>
			)
		}
		else if (!this.state.validToken) {
			return (
			  	<SUSIWrapper>
					<h1>This reset password link has expired</h1>
			  	</SUSIWrapper>
			)
		}
		else {
			return (
				<SUSIWrapper>
					<div style={{marginTop: 10}}>
						<form onSubmit={this.onSubmit}>
							<h1>yay4me</h1>
							<br/>
							{this.state.resetError &&
								<p><font color="red">{this.state.resetErrorMessage}</font></p>
							}
							<p>Enter New Password</p>
							<div className="form-group">
								<label>Password: </label>
								<input 
									type="password" 
									className="form-control"
									value={this.state.password}
									onChange={this.onChangePassword}
								/>
							</div>
							<div className="form-group">
								<label>Confirm Password: </label>
								<input 
									type="password" 
									className="form-control"
									value={this.state.confirmPassword}
									onChange={this.onChangeConfirmPassword}
								/>
							</div>
							<div className="form-group">
								<input type="submit" value="Reset Password" className="btn btn-primary" />
							</div>
						</form>
					</div>
				</SUSIWrapper>
			)
		}
    }
}
import React, { Component } from 'react';
import axios from 'axios';
import { appConfig } from "../utils/config.js";
import { getSessionId, removeSessionId }  from "../utils/sessionUtil.js";

export default class LogOut extends Component {
	componentDidMount = (e) => {
		let sessionId = getSessionId();
		if (sessionId) {
			axios.post(appConfig.apihost+'/api/remove-session/', {sessionId: sessionId})
			.then()
			.catch(error => {
				console.log('error',error);
			});
		}
		removeSessionId();
		this.props.history.push("/");
	}

	render() {
		return (
			<br/>
		)
	}

}
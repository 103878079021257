import React, { Component } from 'react';
import SUSIWrapper from "../layouts/signupsignin.layout";

export default class Signin extends Component {

	render() {
		return (
			<SUSIWrapper>
				<div style={{marginTop: 10}}>
					<h1>yay4me</h1>
					<br/>
					<p>Contact Support:</p>
					
					<p>Send us an&nbsp;
					<a href="mailto:neal_williams@icloud.com?Subject=yay4me%20Support" target="_top">email</a>
					</p>
					
				</div>
								
			</SUSIWrapper>
		)
    }

}
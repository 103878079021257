var appConfig = {};

if (process.env.NODE_ENV === 'production') {
	appConfig.apihost = 'https://www.yay4me.com';
}
else {
	appConfig.apihost = 'http://localhost:3010';
}

exports.appConfig = appConfig;

import React, { Component } from 'react';
import axios from 'axios';
import { appConfig } from "../utils/config.js";
import { getSessionId }  from "../utils/sessionUtil.js";
import SIWrapper from "../layouts/signedin.layout";

import { Button, Modal } from 'react-bootstrap';

export default class UserHome extends Component {

	constructor(props) {
		super(props);
		this.state = {
			userName: '',
			userType: '',
			hasCoach: false,
			profileOK: false,
			error: false,
			doneloading: false,
			width: window.innerWidth 
		}
	}

	componentDidMount = (e) => {
		window.addEventListener('resize', this.updateWindowWidth);
		// Validate the session
		let sessionId = getSessionId();
		axios.post(appConfig.apihost+'/api/load-user-home/', {sessionId: sessionId})
		.then(response => {
			if (response.data.invalidSession) {
		        this.props.history.push("/logout");
			}
			else if (response.data.success) {
				axios.post(appConfig.apihost+'/api/get-aicoach/', {sessionId: sessionId})
				.then(response2 => {
					if (response2.data.success) {
						this.setState({
							userName: response.data.session.user_name,
							userType: response.data.session.user_type,
							hasCoach: response2.data.hasCoach,
							profileOK: response.data.profileOK,
							cantYetModalShow: false,
							doneloading: true
						});
					}
					else {
						this.setState({
							error: true
						});
					}
				})
				.catch(error => {
					console.log('error',error);
				});
			}
			else {
				this.setState({
					error: true
				});
			}
		})
		.catch(error => {
			console.log('error',error);
		});
	}

	componentWillUnmount = () => {
		window.removeEventListener('resize', this.updateWindowWidth);
	}

	updateWindowWidth = () => {
		this.setState({ width: window.innerWidth });
	}

    editAIClick = (e) => {
		if (this.state.profileOK) {
			this.props.history.push("/editai");
		}
		else {
			this.handleCantYetModalShow();
		}
    }

    viewCalendarClick = (e) => {
		this.props.history.push("/calendar");
    }

    viewRemindersClick = (e) => {
		this.props.history.push("/reminders");
    }

	handleCantYetModalShow = () => {
		this.setState({ cantYetModalShow: true });
	}

	handleCantYetModalHide = () => {
		this.setState({ cantYetModalShow: false });
	}

	render() {
		if (!this.state.doneloading) {
			return (
				<br/>
			)
		}
		else if (this.state.error) {
			return (
				<SIWrapper name={this.state.userName} showbackground={true}>
					<p>Server Error</p>
				</SIWrapper>
			)
		}
		else {
			if (this.state.width <= 500) {
				return (
					<div style={{padding: 25, backgroundColor: "#eee"}}>
						<h1>Sign in on the yay4me app on your smart phone</h1>
					</div>
				)
			}
			else {
				return (
					<SIWrapper name={this.state.userName} userType={this.state.userType} showbackground={true}>
						<div style={{marginTop: 10}}>
							<h2>User Home</h2>
							<br/>
							<div>
								{!this.state.profileOK && 
								  <><Button variant="outline-secondary" onClick={()=> {this.props.history.push("/editprofile")}}>Complete Your Profile</Button><br/><br/></>
								}
								{this.state.hasCoach
									?
								  <><Button variant="outline-secondary" onClick={this.editAIClick}>Edit AI Coach</Button><br/><br/></>
									:
								  <><Button variant="outline-secondary" onClick={this.editAIClick}>Setup Your AI Coach</Button><br/><br/></>
								}
								<Button variant="outline-secondary"  onClick={this.viewRemindersClick}>Reminders</Button>
								<br/><br/>
								<Button variant="outline-secondary"  onClick={this.viewCalendarClick}>Calendar View</Button>
							</div>
						</div>

						<Modal show={this.state.cantYetModalShow}
							dialogClassName="modal-90w"
							aria-labelledby="example-custom-modal-styling-title">
						<Modal.Header>
							<Modal.Title id="example-custom-modal-styling-title">You Can't Setup Coach Yet</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<p>You must fill out your profile including your nickname, timezone and gender before you can create your AI coach.</p>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={this.handleCantYetModalHide}>OK</Button>
						</Modal.Footer>
						</Modal>

					</SIWrapper>
				)
			}
		}
    }
}
import React, { Component } from 'react';
import axios from 'axios';
import { appConfig } from "../utils/config.js";
import { getSessionId, saveSessionId } from "../utils/sessionUtil.js";
import SUSIWrapper from "../layouts/signupsignin.layout";
import { Modal, Button } from 'react-bootstrap';


export default class Signin extends Component {

	constructor(props) {
		super(props);
	
		this.state = {
			email: '',
			password: '',
			signinError: false,
			signinErrorMessage: '',
			showPasswordResetButton: false,
			resetPasswordModalShow: false,
			resetSuccess: false,
			resetFail: false,
			resetEmail: '',
			doneloading: false
		}
	}

	componentDidMount = (e) => {
		// Validate the session
		let sessionId = getSessionId();
		if (sessionId) {
			axios.post(appConfig.apihost+'/api/validate-session/', {sessionId: sessionId})
			.then(response => {
				if (response.data.success) {
					if (response.data.session.user_type === 'admin' || response.data.session.user_type === 'super-admin') {
						this.props.history.push("/adminhome");
					}
					else if (response.data.session.user_type === 'coach') this.props.history.push("/coachhome");
					else if (response.data.session.user_type === 'user') this.props.history.push("/userhome");
				}
				else {
					this.setState({
						doneloading: true
					});
				}
			})
			.catch(error => {
				console.log('error',error);
				this.setState({
					doneloading: true
				});
			});
		}
		else {
			this.setState({
				doneloading: true
			});
		}
	}

    onChangeEmail = (e) => {
        this.setState({
            email: e.target.value
        });
    }

    onChangePassword = (e) => {
        this.setState({
            password: e.target.value
        });
    }

	onSubmit = (e) => {
		e.preventDefault();
        const signinValues = {
            email: this.state.email,
            password: this.state.password
        };
        axios.post(appConfig.apihost+'/api/signin', signinValues)
        .then(response => {			
			if (response.data.success) {
				saveSessionId(response.data.sessionId);
				if (response.data.type === 'coach') this.props.history.push("/coachhome");
				else if (response.data.type === 'admin' || response.data.type === 'super-admin') this.props.history.push("/adminhome");
				else this.props.history.push("/userhome");
			}
			else {
				this.setState({
					signinError: true,
					signinErrorMessage: response.data.message,
					showPasswordResetButton: response.data.askForPasswordReset
				});
			}
        })
		.catch(error => {
			console.log('error:',error);
		});
	}

    onChangeResetRequest = (e) => {
        this.setState({
            resetEmail: e.target.value
        });
    }

    onSendResetRequest = (e) => {
		this.handleResetPasswordModalHide();
		axios.post(appConfig.apihost+'/api/send-reset-password-invitation', {resetEmail: this.state.resetEmail})
		.then(response => {
			if (response.data.success) {
				this.handleResetSuccessModalShow();
			}
			else {
				this.handleResetFailModalShow();
			}
		})
		.catch(error => {
			console.log('error',error);
		});
	}

	handleResetPasswordModalShow = () => {
		this.setState({ resetPasswordModalShow: true });
	}

	handleResetPasswordModalHide = () => {
		this.setState({ resetPasswordModalShow: false });
	}

	handleResetSuccessModalShow = () => {
		this.setState({ resetSuccess: true });
	}

	handleResetSuccessModalHide = () => {
		this.setState({ resetSuccess: false });
	}

	handleResetFailModalShow = () => {
		this.setState({ resetFail: true });
	}

	handleResetFailModalHide = () => {
		this.setState({ resetFail: false });
	}

	render() {
		if (!this.state.doneloading) {
			return (
				<br/>
			)
		}
		else {
			return (
				<SUSIWrapper>
					<div style={{marginTop: 10}}>
						<form onSubmit={this.onSubmit}>
							<h1>yay4me</h1>
							<br/>
							{this.state.signinError &&
								<p><font color="red">{this.state.signinErrorMessage}</font></p>
							}
							<div className="form-group"> 
								<label>Email: </label>
								<input  type="email"
									className="form-control"
									value={this.state.email}
									onChange={this.onChangeEmail}
								/>
							</div>
							<div className="form-group">
								<label>Password: </label>
								<input 
									type="password" 
									className="form-control"
									value={this.state.password}
									onChange={this.onChangePassword}
								/>
							</div>
							<div className="form-group">
								<input type="submit" value="Sign In" className="btn btn-primary" />
							</div>
						</form>
						{this.state.showPasswordResetButton &&
							<p><button style={{background: "none"}} onClick={this.handleResetPasswordModalShow}>Reset Password</button></p>
						}

						<div style={{fontSize: '75%', textAlign: 'right'}}>
						<a href="/support">Customer Support</a>
						</div>

					</div>
					
					<Modal show={this.state.resetPasswordModalShow}
          				dialogClassName="modal-90w"
          				aria-labelledby="example-custom-modal-styling-title">
					<Modal.Header>
						<Modal.Title id="example-custom-modal-styling-title">Reset Password</Modal.Title>
					</Modal.Header>
			            <input type="email" placeholder={"Enter Account Email"} style={{margin: "5px 35px 5px 35px"}} onChange={this.onChangeResetRequest}/>
					<Modal.Footer>
						<Button variant="secondary" onClick={this.handleResetPasswordModalHide}>Cancel</Button>
						<Button variant="primary" onClick={this.onSendResetRequest}>Send Request</Button>
					</Modal.Footer>
					</Modal>

					<Modal show={this.state.resetSuccess}
          				dialogClassName="modal-90w"
          				aria-labelledby="example-custom-modal-styling-title">
					<Modal.Header>
						<Modal.Title id="example-custom-modal-styling-title">Password Reset Email Sent</Modal.Title>
					</Modal.Header>
					<Modal.Footer>
						<Button variant="secondary" onClick={this.handleResetSuccessModalHide}>OK</Button>
					</Modal.Footer>
					</Modal>

					<Modal show={this.state.resetFail}
          				dialogClassName="modal-90w"
          				aria-labelledby="example-custom-modal-styling-title">
					<Modal.Header>
						<Modal.Title id="example-custom-modal-styling-title">Email Not Found In System</Modal.Title>
					</Modal.Header>
					<Modal.Footer>
						<Button variant="secondary" onClick={this.handleResetFailModalHide}>OK</Button>
					</Modal.Footer>
					</Modal>
					
				</SUSIWrapper>
			)
		}
    }
}
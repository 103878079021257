import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import Signin from "./components/signin.component";
import UserSignup from "./components/usersignup.component";
import CoachSignup from "./components/coachsignup.component";
import CoachHome from "./components/coachhome.component";
import UserHome from "./components/userhome.component";
import AdminHome from "./components/adminhome.component";
import EditProfile from "./components/editprofile.component";
import EditAI from "./components/editai.component";
import Calendar from "./components/calendar.component";
import Reminders from "./components/reminders.component";
import SignOut from "./components/signout.component";
import ResetPassword from "./components/resetpassword.component";
import Support from "./components/support.component";
import NotFound from "./components/notfound.component";

function App() {
	return (
		<Router>
			<Switch>
				<Route exact path="/" component={Signin} />
				<Route path="/usersignup/:tkn" component={UserSignup} />
				<Route path="/coachsignup/:tkn" component={CoachSignup} />
				<Route path="/userhome" component={UserHome} />
				<Route path="/coachhome" component={CoachHome} />
				<Route path="/adminhome" component={AdminHome} />
				<Route path="/editprofile" component={EditProfile} />
				<Route path="/editai" component={EditAI} />
				<Route path="/calendar/:usr" component={Calendar} />
				<Route path="/calendar/" component={Calendar} />
				<Route path="/reminders/:usr" component={Reminders} />
				<Route path="/reminders/" component={Reminders} />
				<Route path="/resetpassword/:tkn" component={ResetPassword} />
				<Route path="/signout" component={SignOut} />
				<Route path="/support" component={Support} />
				<Route path="*" component={NotFound} />
			</Switch>
		</Router>
	);
}

export default App;

import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import axios from 'axios';
import { appConfig } from "../utils/config.js";
import { getSessionId } from "../utils/sessionUtil.js";
import SIWrapper from "../layouts/signedin.layout";

import "react-datepicker/dist/react-datepicker.css";

export default class EditProfile extends Component {

	constructor(props) {
		super(props);
	
		this.state = {
			userName: '',
			email: '',
			firstName: '',
			lastName: '',
			nickame: '',
			gender: '',
			timeZone: '',
			userType: '',
			timeZoneTypes: [],
			doneloading: false
		}
	}

	componentDidMount = (e) => {
		// Validate the session
		let sessionId = getSessionId();
		axios.post(appConfig.apihost+'/api/get-profile/', {sessionId: sessionId})
		.then(response => {
			if (response.data.invalidSession) {
		        this.props.history.push("/logout");
			}
			else if (response.data.success) {
				let bd;
				if (response.data.birthdate) bd = new Date(response.data.birthdate);
				this.setState({
					email: response.data.email,
					firstName: response.data.firstName,
					lastName: response.data.lastName,
					nickname: response.data.nickname,
					birthdate: bd,
					gender: response.data.gender,
					timeZone: response.data.timeZone,
					timeZoneTypes: response.data.timeZoneTypes,
					userName: response.data.session.user_name,
					userType: response.data.session.user_type,
					doneloading: true
				});
			}
		})
		.catch(error => {
			console.log('error',error);
		});
	}

    onChangeFirstName = (e) => {
        this.setState({
            firstName: e.target.value
        });
    }

    onChangeLastName = (e) => {
        this.setState({
            lastName: e.target.value
        });
    }

    onChangeNickname = (e) => {
        this.setState({
            nickname: e.target.value
        });
    }

    handleClickGender = (e) => {
        this.setState({
            gender: e.target.value
        });
    }

    handleTZChange = (e) => {
        this.setState({
            timeZone: e.target.value
        });
    }

	handleBirthDateChange = (date) => {
		this.setState({
			birthdate: date
		});
	}

	onSubmit = (e) => {
		e.preventDefault();
		let sessionId = getSessionId();
        const profileValues = {
            sessionId: sessionId,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            nickname: this.state.nickname,
            birthdate: this.state.birthdate,
            gender: this.state.gender,
            timeZone: this.state.timeZone
        };
        axios.post(appConfig.apihost+'/api/update-profile', profileValues)
        .then(response => {			
			if (response.data.invalidSession) {
		        this.props.history.push("/logout");
			}
			else this.props.history.goBack();
        })
		.catch(error => {
			console.log('error:',error);
		});
	}

	timeZoneItems = () => {
		return this.state.timeZoneTypes.map((timezone, i) => {
			return ( <option value={timezone.name}>{timezone.description}</option> )
		});
	}

	render() {
		if (!this.state.doneloading) {
			return (
				<br/>
			)
		}
		else {
			return (
				<SIWrapper name={this.state.userName} userType={this.state.userType} showbackground={true}>
					<div style={{marginTop: 10}}>
						<form onSubmit={this.onSubmit}>
							<h2>Profile</h2>
							<p>email: {this.state.email}</p>
							<div style={{display: "table-row"}}> 
								<div className="form-group" style={{display: "table-cell"}}> 
									<label>First Name: </label>
									<input
										type="text"
										size="40"
										className="form-control"
										value={this.state.firstName}
										onChange={this.onChangeFirstName}
									/>
								</div>
								<div className="form-group" style={{display: "table-cell", paddingLeft: "30px"}}> 
									<label>Last Name: </label>
									<input 
										type="text" 
										size="40"
										className="form-control"
										value={this.state.lastName}
										onChange={this.onChangeLastName}
									/>
								</div>
							</div>
							<br/>
							{this.state.userType === 'user' &&
								<div>
									<div style={{display: "table-row"}}> 
										<div className="form-group" style={{display: "table-cell"}}> 
											<label>Nickname: </label>
											<input 
												type="text" 
												size="40"
												className="form-control"
												value={this.state.nickname}
												onChange={this.onChangeNickname}
											/>
										</div>
										<div className="form-group" style={{display: "table-cell", paddingLeft: "30px"}}> 
											<label>Birth Date: </label><br/>
											<DatePicker
												selected={this.state.birthdate}
												onChange={this.handleBirthDateChange}
											/>
										</div>
									</div>
									<br/>
									<div className="form-group">
										<table><tbody><tr height="70"><td>Gender:&nbsp;</td><td>
										<section className="section-preview" style={{border: "1px solid #d0d0d0", padding: "6px"}}>
										<div className="custom-control custom-radio custom-control-inline">
										  <input type="radio" className="custom-control-input" id="male" value="male" name="gender1" checked={this.state.gender === 'male'} onChange={this.handleClickGender}/>
										  <label className="custom-control-label" htmlFor="male">Male</label>
										</div>
										<div className="custom-control custom-radio custom-control-inline">
										  <input type="radio" className="custom-control-input" id="female" value="female" name="gender1" checked={this.state.gender === 'female'} onChange={this.handleClickGender}/>
										  <label className="custom-control-label" htmlFor="female">Female</label>
										</div>
									  </section>
										</td><td width="50"></td><td>Time Zone:&nbsp;</td><td>
										<select value={this.state.timeZone} className="browser-default custom-select" onChange={this.handleTZChange}>
											{ this.timeZoneItems() }
										</select>
										</td></tr></tbody></table>
									</div>
								</div>
							}
							<div className="form-group">
								<input type="submit" value="Update" className="btn btn-primary" />
							</div>
						</form>
					</div>
				</SIWrapper>
			)
		}
    }
}
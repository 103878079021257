import React, { Component } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';

import Background from "../images/sunset.jpg";

export default class SignedinWrapperComponent extends Component {
  render() {
 	let width = "800px";
 	if (this.props.width) width = this.props.width;
    return (	
		<div>
			<Navbar bg="light" expand="lg">
			  <Navbar.Brand href="#home">yay4me</Navbar.Brand>
			  <Navbar.Toggle aria-controls="basic-navbar-nav" />
			  <Navbar.Collapse id="basic-navbar-nav" >
				<Nav className="ml-auto">
				  <NavDropdown title={this.props.name + ' '} id="basic-nav-dropdown">
					<NavDropdown.Item href="/">Home</NavDropdown.Item>
					<NavDropdown.Item href="/editprofile">Edit Profile</NavDropdown.Item>
					<NavDropdown.Item href="/signout">Sign Out</NavDropdown.Item>
				  </NavDropdown>
				  <div style={{width: "80px"}}></div>
				</Nav>
			  </Navbar.Collapse>
			</Navbar>
			{this.props.showbackground &&
				<div style={{height: "1500px", backgroundImage: "url(" + Background + ")", backgroundRepeat: "no-repeat", backgroundSize: "contain"}}>
					<div style={{padding: "20px 25px 20px", width: width, height: "auto", float: "right", backgroundColor: "#eee", marginRight: "50px", marginTop: "50px"}}>
						{this.props.children}
					</div>
				</div>
			}
			{!this.props.showbackground &&
				<div>
					{this.props.children}
				</div>
			}
		</div>
    );
  }
}
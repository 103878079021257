import React, { Component } from 'react';
import axios from 'axios';
import { appConfig } from "../utils/config.js";
import { getSessionId }  from "../utils/sessionUtil.js";
import SIWrapper from "../layouts/signedin.layout";
import StepWizard from 'react-step-wizard';
import Timekeeper from 'react-timekeeper';
import { Form, Button, Modal } from 'react-bootstrap';

import Female1 from "../images/female1.png";
import Female2 from "../images/female2.png";
import Female3 from "../images/female3.png";
import Female4 from "../images/female4.png";
import Female5 from "../images/female5.png";
import Female6 from "../images/female6.png";
import Male1 from "../images/male1.png";
import Male2 from "../images/male2.png";
import Male3 from "../images/male3.png";
import Male4 from "../images/male4.png";
import Male5 from "../images/male5.png";
import Male6 from "../images/male6.png";

class Configuration extends React.Component {

	render() {
		let imgSrc;
		switch (this.props.mainThis.state.appearance)
		{
			case 'female1': 
				imgSrc = Female1;
				break;
			case 'female2': 
				imgSrc = Female2;
				break;
			case 'female3': 
				imgSrc = Female3;
				break;
			case 'female4': 
				imgSrc = Female4;
				break;
			case 'female5': 
				imgSrc = Female5;
				break
			case 'female6': 
				imgSrc = Female6;
				break;
			case 'male1': 
				imgSrc = Male1;
				break;
			case 'male2': 
				imgSrc = Male2;
				break;
			case 'male3': 
				imgSrc = Male3;
				break;
			case 'male4': 
				imgSrc = Male4;
				break;
			case 'male5': 
				imgSrc = Male5;
				break;
			case 'male6': 
				imgSrc = Male6;
				break;
			default:
		}
		return (
			<div>
				{!this.props.mainThis.state.coachConfigured &&
					<p><br/><Button variant="outline-secondary" onClick={this.props.nextStep}>Create Your Coach</Button></p>
				}
				{this.props.mainThis.state.coachConfigured &&
					<>
					<table>
					<tbody>
					<tr><td align="right"><button style={{border: "none", background: "none", textDecoration: "underline"}} onClick={()=>this.props.goToStep(2)}>Gender:</button></td><td>{this.props.mainThis.state.saved_gender}</td></tr>
					<tr><td align="right"><button style={{border: "none", background: "none", textDecoration: "underline"}} onClick={()=>this.props.goToStep(2)}>Appearance:</button></td><td>{this.props.mainThis.state.saved_appearance && <img alt="" width="70" height="70" src={imgSrc}/>}</td></tr>
					<tr><td align="right"><button style={{border: "none", background: "none", textDecoration: "underline"}} onClick={()=>this.props.goToStep(3)}>Intensity Level:</button></td><td>{this.props.mainThis.state.saved_intensity}</td></tr>
					<tr><td align="right"><button style={{border: "none", background: "none", textDecoration: "underline"}} onClick={()=>this.props.goToStep(4)}>Personality Type:</button></td><td>{this.props.mainThis.state.saved_personality}</td></tr>
					<tr><td align="right"><button style={{border: "none", background: "none", textDecoration: "underline"}} onClick={()=>this.props.goToStep(5)}>Charater Trait:</button></td><td>{this.props.mainThis.state.saved_characterTrait}</td></tr>
					<tr><td align="right"><button style={{border: "none", background: "none", textDecoration: "underline"}} onClick={()=>this.props.goToStep(6)}>Quirks:</button></td><td>{this.props.mainThis.state.saved_quirks.toString()}</td></tr>
					<tr><td align="right"><button style={{border: "none", background: "none", textDecoration: "underline"}} onClick={()=>this.props.goToStep(7)}>Chattiness:</button></td><td>{this.props.mainThis.state.saved_chattiness}</td></tr>
					<tr><td align="right"><button style={{border: "none", background: "none", textDecoration: "underline"}} onClick={()=>this.props.goToStep(8)}>Name:</button></td><td>{this.props.mainThis.state.saved_ainame}</td><td></td></tr>
					</tbody>
					</table>
					<br/>
					<Button variant="outline-secondary" onClick={this.props.nextStep}>Coach Setup Wizard</Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					<Button variant="outline-secondary" onClick={()=>this.props.goToStep(9)}>Contact Schedule</Button>
					</>
				}
			</div>
		);
	}
}

class Step1 extends React.Component {

    save = () => {
		this.props.mainThis.saveChanges(() => {
			this.props.mainThis.loadAICoachData(() => {
				this.props.goToStep(1);
			});
        });
    }

	render() {
		return (
			<div>
				<p/>
				<b>Gender</b>
				<Form>
					<Form.Group>
						<table>
						<tbody>
						<tr>
						<td><Form.Check type="radio" value="male" name="gender" label="Male" checked={this.props.mainThis.state.gender === 'male'} onChange={this.props.mainThis.handleClickGender} /></td>
						<td width="25px"></td>
						<td><Form.Check type="radio" value="female" name="gender" label="Female" checked={this.props.mainThis.state.gender === 'female'} onChange={this.props.mainThis.handleClickGender} /></td>
						</tr>
						</tbody>
						</table>
					</Form.Group>
				</Form>
				<b>Appearance</b>
				<div style={{height: "10px"}}></div>
				<Form>
					<Form.Group>
						<table><tbody>
								{this.props.mainThis.state.gender === 'female' ?
									<>	
									<tr>
										<td><Form.Check type="radio" value="female1" name="appearance" checked={this.props.mainThis.state.appearance === 'female1'} onChange={this.props.mainThis.handleClickAppearance} /></td><td><img alt="" width="150" height="150" src={Female1}/></td><td width="60"></td>
										<td><Form.Check type="radio" value="female2" name="appearance" checked={this.props.mainThis.state.appearance === 'female2'} onChange={this.props.mainThis.handleClickAppearance} /></td><td><img alt="" width="150" height="150" src={Female2}/></td><td width="60"></td>
										<td><Form.Check type="radio" value="female3" name="appearance" checked={this.props.mainThis.state.appearance === 'female3'} onChange={this.props.mainThis.handleClickAppearance} /></td><td><img alt="" width="150" height="150" src={Female3}/></td><td width="60"></td>
									</tr>
									<tr>
										<td height="20px"></td>
									</tr>
									<tr>
										<td><Form.Check type="radio" value="female4" name="appearance" checked={this.props.mainThis.state.appearance === 'female4'} onChange={this.props.mainThis.handleClickAppearance} /></td><td><img alt="" width="150" height="150" src={Female4}/></td><td width="60"></td>
										<td><Form.Check type="radio" value="female5" name="appearance" checked={this.props.mainThis.state.appearance === 'female5'} onChange={this.props.mainThis.handleClickAppearance} /></td><td><img alt="" width="150" height="150" src={Female5}/></td><td width="60"></td>
										<td><Form.Check type="radio" value="female6" name="appearance" checked={this.props.mainThis.state.appearance === 'female6'} onChange={this.props.mainThis.handleClickAppearance} /></td><td><img alt="" width="150" height="150" src={Female6}/></td><td width="60"></td>
									</tr>
									</>
								:
									<>
									<tr>
										<td><Form.Check type="radio" value="male1" name="appearance"  checked={this.props.mainThis.state.appearance === 'male1'} onChange={this.props.mainThis.handleClickAppearance} /></td><td><img alt="" width="150" height="150" src={Male1}/></td><td width="60"></td>
										<td><Form.Check type="radio" value="male2" name="appearance"  checked={this.props.mainThis.state.appearance === 'male2'} onChange={this.props.mainThis.handleClickAppearance} /></td><td><img alt="" width="150" height="150" src={Male2}/></td><td width="60"></td>
										<td><Form.Check type="radio" value="male3" name="appearance"  checked={this.props.mainThis.state.appearance === 'male3'} onChange={this.props.mainThis.handleClickAppearance} /></td><td><img alt="" width="150" height="150" src={Male3}/></td><td width="60"></td>
									</tr>
									<tr>
										<td height="20px"></td>
									</tr>
									<tr>
										<td><Form.Check type="radio" value="male4" name="appearance"  checked={this.props.mainThis.state.appearance === 'male4'} onChange={this.props.mainThis.handleClickAppearance} /></td><td><img alt="" width="150" height="150" src={Male4}/></td><td width="60"></td>
										<td><Form.Check type="radio" value="male5" name="appearance"  checked={this.props.mainThis.state.appearance === 'male5'} onChange={this.props.mainThis.handleClickAppearance} /></td><td><img alt="" width="150" height="150" src={Male5}/></td><td width="60"></td>
										<td><Form.Check type="radio" value="male6" name="appearance"  checked={this.props.mainThis.state.appearance === 'male6'} onChange={this.props.mainThis.handleClickAppearance} /></td><td><img alt="" width="150" height="150" src={Male6}/></td><td width="60"></td>
									</tr>
									</>
								}
						</tbody></table>
					</Form.Group>
				</Form>
				<br />
				<Button variant="outline-secondary" onClick={()=>this.props.goToStep(1)}>Cancel</Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				<Button variant="outline-secondary" onClick={this.props.nextStep}>Next Step</Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				{(this.props.mainThis.state.coachConfigured && this.props.mainThis.state.saveable) && 
					<Button variant="outline-secondary" onClick={this.save}>Save Changes</Button>
				}
			</div>
		);
	}
}

class Step2 extends React.Component {

 	coachIntensityItems = () => {
		return this.props.mainThis.state.coachIntensityTypes.map((intensity, i) => {
			return ( <Form.Check key={i} type="radio" value={intensity.type} name="intensity" label={intensity.description} checked={this.props.mainThis.state.intensity === intensity.type} onChange={this.props.mainThis.handleClickIntensity} /> )
		});
	}

    save = () => {
		this.props.mainThis.saveChanges(() => {
			this.props.mainThis.loadAICoachData(() => {
				this.props.goToStep(1);
			});
        });
    }

	render() {
		return (
			<div>
				<p/>
				<b>Intensity Level Type</b>
				<p/>
				<p>How intense do you want your coach? Do you wany someone who will sweet talk you into getting things done, or someone who can't stand wimps?</p>
				<Form>
					<Form.Group>
						{ this.coachIntensityItems() }
					</Form.Group>
				</Form>
				<br/>
				<Button variant="outline-secondary" onClick={()=>this.props.goToStep(1)}>Cancel</Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				<Button variant="outline-secondary" onClick={this.props.previousStep}>Previous Step</Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				<Button variant="outline-secondary" onClick={this.props.nextStep}>Next Step</Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				{(this.props.mainThis.state.coachConfigured && this.props.mainThis.state.saveable) && 
					<Button variant="outline-secondary" onClick={this.save}>Save Changes</Button>
				}
			</div>
		);
	}
}

class Step3 extends React.Component {

 	coachPersonalityItems = () => {
		return this.props.mainThis.state.coachPersonalityTypes.map((personality, i) => {
			return ( <Form.Check key={i} type="radio" value={personality.type} name="personality" label={personality.description} checked={this.props.mainThis.state.personality === personality.type} onChange={this.props.mainThis.handleClickPersonality} /> )
		});
	}

    save = () => {
		this.props.mainThis.saveChanges(() => {
			this.props.mainThis.loadAICoachData(() => {
				this.props.goToStep(1);
			});
        });
    }

	render() {
		return (
			<div>
				<p/>
				<b>Personality Type</b>
				<p/>
				<p>What kind of personality do you want your coach to have?</p>
				<Form>
					<Form.Group >
						{ this.coachPersonalityItems() }
					</Form.Group>
				</Form>
				<br/>
				<Button variant="outline-secondary" onClick={()=>this.props.goToStep(1)}>Cancel</Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				<Button variant="outline-secondary" onClick={this.props.previousStep}>Previous Step</Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				<Button variant="outline-secondary" onClick={this.props.nextStep}>Next Step</Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				{(this.props.mainThis.state.coachConfigured && this.props.mainThis.state.saveable) && 
					<Button variant="outline-secondary" onClick={this.save}>Save Changes</Button>
				}
			</div>
		);
	}
}

class Step4 extends React.Component {

 	coachCharacterTraitItems = () => {
		return this.props.mainThis.state.coachCharacterTraitTypes.map((traits, i) => {
			return ( <Form.Check key={i} type="radio" value={traits.type} name="character-trait" label={traits.description} checked={this.props.mainThis.state.characterTrait === traits.type} onChange={this.props.mainThis.handleClickCharacterTrait} /> )
		});
	}

	save = () => {
		this.props.mainThis.saveChanges(() => {
			this.props.mainThis.loadAICoachData(() => {
				this.props.goToStep(1);
			});
        });
    }

	render() {
		return (
			<div>
				<p/>
				<b>Character Trait</b>
				<p/>
				<Form>
					<Form.Group >
						{ this.coachCharacterTraitItems() }
					</Form.Group>
				</Form>
				<br/>
				<Button variant="outline-secondary" onClick={()=>this.props.goToStep(1)}>Cancel</Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				<Button variant="outline-secondary" onClick={this.props.previousStep}>Previous Step</Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				<Button variant="outline-secondary" onClick={this.props.nextStep}>Next Step</Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				{(this.props.mainThis.state.coachConfigured && this.props.mainThis.state.saveable) && 
					<Button variant="outline-secondary" onClick={this.save}>Save Changes</Button>
				}
			</div>
		);
	}
}

class Step5 extends React.Component {

	coachQuirkItems = () => {
		return this.props.mainThis.state.coachQuirkTypes.map((quirk, i) => {
			return ( <Form.Check key={i} type="checkbox" value={quirk.type} checked={(this.props.mainThis.state.quirks.indexOf(quirk.type) >= 0)} label={quirk.description} onChange={this.props.mainThis.handleClickQuirks} /> )
		});
	}

	save = () => {
		this.props.mainThis.saveChanges(() => {
			this.props.mainThis.loadAICoachData(() => {
				this.props.goToStep(1);
			});
		});
	}

	render() {
		return (
			<div>
				<p/>
				<b>Quirks</b>
				<p/>
				<p>Select none, or up to three of the following:</p>
				<Form>
					<Form.Group>
						{ this.coachQuirkItems() }
					</Form.Group>
				</Form>
				<br/>
				<Button variant="outline-secondary" onClick={()=>this.props.goToStep(1)}>Cancel</Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				<Button variant="outline-secondary" onClick={this.props.previousStep}>Previous Step</Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				<Button variant="outline-secondary" onClick={this.props.nextStep}>Next Step</Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				{(this.props.mainThis.state.coachConfigured && this.props.mainThis.state.saveable) && 
					<Button variant="outline-secondary" onClick={this.save}>Save Changes</Button>
				}
			</div>
		);
	}
}

class Step6 extends React.Component {

	coachChatinessItems = () => {
		return this.props.mainThis.state.coachChattinessTypes.map((chattiness, i) => {
			return ( <Form.Check key={i} type="radio" value={chattiness.type} name="chattiness" label={chattiness.description} checked={this.props.mainThis.state.chattiness === chattiness.type} onChange={this.props.mainThis.handleClickChattiness} /> )
		});
	}

    save = () => {
		this.props.mainThis.saveChanges(() => {
			this.props.mainThis.loadAICoachData(() => {
				this.props.goToStep(1);
			});
        });
    }

	render() {
		return (
			<div>
				<p/>
				<b>Chattiness</b>
				<p/>
				<p>How chatty do you want your AI coach?</p>
				<Form>
					<Form.Group>

						{ this.coachChatinessItems() }

					</Form.Group>
				</Form>
				<br/>
				<Button variant="outline-secondary" onClick={()=>this.props.goToStep(1)}>Cancel</Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				<Button variant="outline-secondary" onClick={this.props.previousStep}>Previous Step</Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				<Button variant="outline-secondary" onClick={this.props.nextStep}>Next Step</Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				{(this.props.mainThis.state.coachConfigured && this.props.mainThis.state.saveable) && 
					<Button variant="outline-secondary" onClick={this.save}>Save Changes</Button>
				}
			</div>
		);
	}
}

class Step7 extends React.Component {

    save = () => {
		this.props.mainThis.saveChanges(() => {
			this.props.mainThis.loadAICoachData(() => {
				this.props.goToStep(1);
			});
        });
    }

	render() {
		// determine if we can save
		let readyToSave;
		let fakeSave;
		if (this.props.mainThis.state.coachConfigured && this.props.mainThis.state.saveable) {
			readyToSave = true;
		}
		else if (!this.props.mainThis.state.coachConfigured) {
			if (this.props.mainThis.state.gender
				&& this.props.mainThis.state.intensity
				&& this.props.mainThis.state.personality
				&& this.props.mainThis.state.characterTrait
				&& this.props.mainThis.state.chattiness
				&& this.props.mainThis.state.appearance
				&& this.props.mainThis.state.ainame) {
					readyToSave = true;
			}
			else {
				fakeSave = true;
			}
		}
		
		return (
			<div>
				<p/>
				<b>Give your coach a name</b>
				<p/>
				<form>
					<div className="form-group"> 
						<label>Name: </label>
						<input type="text"
							className="form-control"
							value={this.props.mainThis.state.ainame}
							onChange={this.props.mainThis.onChangeAIName}
						/>
					</div>
				</form>
				<Button variant="outline-secondary" onClick={()=>this.props.goToStep(1)}>Cancel</Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				<Button variant="outline-secondary" onClick={this.props.previousStep}>Previous Step</Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				{ readyToSave ? <Button variant="primary" onClick={this.save}>Save Changes</Button>
					: 
					( fakeSave ? <Button variant="outline-secondary" onClick={this.props.mainThis.handleCantSaveModalShow}>Save Changes</Button>
						: <></>
					)
				}
			</div>
		);
	}
}

class Step8 extends React.Component {

    save = () => {
		this.props.mainThis.saveChanges(() => {
			this.props.mainThis.loadAICoachData(() => {
				this.props.goToStep(1);
			});
        });
    }

	render() {
		return (
			<div>
				{this.props.mainThis.state.showTimePicker &&
					<div style={{position: "absolute", top: "0px", left: "380px", zIndex: "10"}}>
						<Timekeeper time={this.props.mainThis.state.timePickerTime} onDoneClick={this.props.mainThis.doneTimePicker}/>
					</div>
				}
				<p/>
				<b>Contact Schedule</b>
				<p/>
				<Form.Check onChange={this.props.mainThis.handleClickContactMe} checked={this.props.mainThis.state.contactMe === 1} label="Enable coach chat " />
				<br/>
				Chat with me during these hours
				<table cellPadding="5px">
				<tbody>
				<tr><td align="right">Begin:</td><td align="right">{this.props.mainThis.formatTime(this.props.mainThis.state.contactBeginTime)}</td><td><Button disabled={this.props.mainThis.state.showTimePicker} size="sm" variant="outline-secondary" onClick={() => this.props.mainThis.showTimePickerUI('begin')}>Edit</Button></td></tr>
				<tr><td align="right">End:</td><td align="right">{this.props.mainThis.formatTime(this.props.mainThis.state.contactEndTime)}</td><td><Button disabled={this.props.mainThis.state.showTimePicker} size="sm" variant="outline-secondary" onClick={() => this.props.mainThis.showTimePickerUI('end')}>Edit</Button></td></tr>
				</tbody>
				</table>
				<br/>
				Remind me of my days schedule at this time
				<Form.Check type="radio" value="day-before" name="reminder" label="The day before" checked={this.props.mainThis.state.reminderOption === 'day-before'} onChange={this.props.mainThis.handleClickReminder} />
				<Form.Check type="radio" value="day-of" name="reminder" label="The day of" checked={this.props.mainThis.state.reminderOption === 'day-of'} onChange={this.props.mainThis.handleClickReminder} />
				<table cellPadding="5px">
				<tbody>
				<tr><td align="right">Reminder:</td><td>{this.props.mainThis.formatTime(this.props.mainThis.state.reminderTime)}</td><td><Button disabled={this.props.mainThis.state.showTimePicker} size="sm" variant="outline-secondary" onClick={() => this.props.mainThis.showTimePickerUI('reminder')}>Edit</Button></td></tr>
				</tbody>
				</table>
				<br/>
				<Button variant="outline-secondary" onClick={()=>this.props.goToStep(1)}>Cancel</Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				{this.props.mainThis.state.saveable && 
					<Button variant="primary" onClick={this.save}>Save Changes</Button>
				}
			</div>
		);
	}
}

export default class EditAI extends Component {

	constructor(props) {
		super(props);
	
		this.state = {
			userType: '',
			userName: '',
			coachIntensityTypes: [],
			coachPersonalityTypes: [],
			coachCharacterTraitTypes: [],
			coachQuirkTypes: [],
			coachChattinessTypes: [],
			gender: 'male',
			appearance: 'male1',
			intensity: '',
			personality: '',
			characterTrait: '',
			quirks: [],
			chattiness: '',
			ainame: '',
			contactMe: 1,
			contactBeginTime: {hour: 7, minute: 0},
			contactEndTime: {hour: 22, minute: 0},
			reminderOption: 'day-before',
			reminderTime: {hour: 21, minute: 0},
			saved_gender: '',
			saved_appearance: '',
			saved_intensity: '',
			saved_personality: '',
			saved_characterTrait: '',
			saved_quirks: [],
			saved_chattiness: '',
			saved_ainame: '',
			saved_contactMe: 1,
			saveable: false,
			showTimePicker: false,
			timePickerTime: '',
			whichTime: '',
			coachConfigured: false,
			cantSaveModalShow: false,
			doneloading: false
		}
	}

	componentDidMount = (e) => {
		// Validate the session
		let sessionId = getSessionId();
		axios.post(appConfig.apihost+'/api/load-aicoach-wizard/', {sessionId: sessionId})
		.then(response => {
			if (response.data.invalidSession) {
		        this.props.history.push("/logout");
			}
			else if (response.data.success) {
				this.loadAICoachData(() => {
					if (response.data.success) {
						this.setState({
							userName: response.data.session.user_name,
							userType: response.data.session.user_type,
							coachIntensityTypes: response.data.coachIntensityTypes,
							coachPersonalityTypes: response.data.coachPersonalityTypes,
							coachCharacterTraitTypes: response.data.coachCharacterTraitTypes,
							coachQuirkTypes: response.data.coachQuirkTypes,
							coachChattinessTypes: response.data.coachChattinessTypes,
							doneloading: true
						});
					}
				});
			}
			else {
				this.setState({
					error: true
				});
			}
		})
		.catch(error => {
			console.log('error',error);
		});
	}

	formatTime = (timeData) => {
		let meridiem = 'am';
		let fmHour = timeData.hour;
		let fmMinute = timeData.minute;
		if (timeData.hour > 11)  meridiem = 'pm';
		if (timeData.hour > 12) fmHour -= 12;
		else if (timeData.hour === 0) fmHour = 12;
		if (fmMinute < 10) fmMinute = '0'+fmMinute;
		return fmHour+':'+fmMinute+' '+meridiem;
	}
	
	handleCantSaveModalShow = () => {
		this.setState({ cantSaveModalShow: true });
	}

	handleCantSaveModalHide = () => {
		this.setState({ cantSaveModalShow: false });
	}

	showTimePickerUI = (which) => {
		let time;
		if (which === 'begin') time = this.state.contactBeginTime;
		if (which === 'end') time = this.state.contactEndTime;
		if (which === 'reminder') time = this.state.reminderTime;
        this.setState({
            showTimePicker: true,
			timePickerTime: time,
            whichTime: which
        });
	}

	doneTimePicker = (timeInfo) => {
		if (this.state.whichTime === 'begin') {
			this.setState({
				contactBeginTime: {hour: timeInfo.hour, minute: timeInfo.minute},
				showTimePicker: false,
				saveable: true,
			});
		}
		if (this.state.whichTime === 'end') {
			this.setState({
				contactEndTime: {hour: timeInfo.hour, minute: timeInfo.minute},
				showTimePicker: false,
				saveable: true,
			});
		}
		if (this.state.whichTime === 'reminder') {
			this.setState({
				reminderTime: {hour: timeInfo.hour, minute: timeInfo.minute},
				showTimePicker: false,
				saveable: true,
			});
		}
	}

    handleClickGender = (e) => {
		let appearance = this.state.appearance;
		if (e.target.value !== this.state.gender) {
			if (e.target.value === 'male') appearance = 'male1';
			else appearance = 'female1';
		}
        this.setState({
			saveable: true,
            gender: e.target.value,
            appearance: appearance
        });
    }

    handleClickIntensity = (e) => {
        this.setState({
			saveable: true,
            intensity: e.target.value
        });
    }

    handleClickPersonality = (e) => {
        this.setState({
			saveable: true,
            personality: e.target.value
        });
    }


    handleClickCharacterTrait = (e) => {
        this.setState({
			saveable: true,
            characterTrait: e.target.value
        });
    }

	handleClickQuirks = (e) => {
		let qrks = this.state.quirks;
		let i = qrks.indexOf(e.target.value);
		if (i === -1) {
			if (qrks.length > 2) qrks.splice(0, 1);
			qrks.push(e.target.value);
		}
		else qrks.splice(i, 1);
        this.setState({
			saveable: true,
            quirks: qrks
        });
	}

    handleClickChattiness = (e) => {
        this.setState({
			saveable: true,
            chattiness: e.target.value
        });
    }

    handleClickAppearance = (e) => {
        this.setState({
			saveable: true,
            appearance: e.target.value
        });
    }

    handleClickContactMe = (e) => {
		let cme = 0;
		if (this.state.contactMe === 0) cme = 1;
        this.setState({
			saveable: true,
            contactMe: cme
        });
    }

    handleClickReminder = (e) => {
        this.setState({
            reminderOption: e.target.value,
			saveable: true
        });
    }

    onChangeAIName = (e) => {
        this.setState({
            ainame: e.target.value,
			saveable: true
        });
    }

    loadAICoachData = (callback) => {
		// Validate the session
		let sessionId = getSessionId();
		axios.post(appConfig.apihost+'/api/get-aicoach/', {sessionId: sessionId})
		.then(response => {
			if (response.data.invalidSession) {
		        this.props.history.push("/logout");
			}
			else if (response.data.success) {
				this.setState({
					saved_gender: response.data.gender,
					gender: response.data.gender,
					saved_appearance: response.data.appearance,
					appearance: response.data.appearance,
					saved_intensity: response.data.intensity,
					intensity: response.data.intensity,
					saved_personality: response.data.personality,
					personality: response.data.personality,
					saved_characterTrait: response.data.characterTrait,
					characterTrait: response.data.characterTrait,
					saved_quirks: response.data.quirks,
					quirks: response.data.quirks,
					saved_chattiness: response.data.chattiness,
					chattiness: response.data.chattiness,
					saved_ainame: response.data.ainame,
					ainame: response.data.ainame,
					contactMe: response.data.contactMe,
					saved_contactMe: response.data.contactMe,
					contactBeginTime: response.data.contactBeginTime,
					contactEndTime: response.data.contactEndTime,
					reminderOption: response.data.reminderOption,
					reminderTime: response.data.reminderTime,
					coachConfigured: true,
					saveable: false
				});
			}
			callback();
		})
		.catch(error => {
			console.log('error',error);
			callback();
		});
	}

    saveChanges = (callback) => {
		// Validate the session
		let sessionId = getSessionId();
		let data = {
			sessionId: sessionId,
			gender: this.state.gender,
			appearance: this.state.appearance,
			intensity: this.state.intensity,
			personality: this.state.personality,
			characterTrait: this.state.characterTrait,
			quirks: this.state.quirks,
			chattiness: this.state.chattiness,
			ainame: this.state.ainame,
			contactMe: this.state.contactMe,
			contactBeginTime: this.state.contactBeginTime,
			contactEndTime: this.state.contactEndTime,
			reminderOption: this.state.reminderOption,
			reminderTime: this.state.reminderTime,
		}
		axios.post(appConfig.apihost+'/api/save-aicoach/', data)
		.then(response => {
			if (response.data.invalidSession) {
		        this.props.history.push("/logout");
			}
			callback();
		})
		.catch(error => {
			console.log('error',error);
			callback();
		});
	}

	render() {
		if (!this.state.doneloading) {
			return (
				<br/>
			)
		}
		else {
			return (
				<SIWrapper name={this.state.userName} userType={this.state.userType} showbackground={true}>
					<div style={{marginTop: 10}}>
						<h1>My AI Coach</h1>
						<StepWizard transitions={{}} >
						  <Configuration mainThis={this} />
						  <Step1 mainThis={this} />
						  <Step2 mainThis={this} />
						  <Step3 mainThis={this} />
						  <Step4 mainThis={this} />
						  <Step5 mainThis={this} />
						  <Step6 mainThis={this} />
						  <Step7 mainThis={this} />
						  <Step8 mainThis={this} />
						</StepWizard>						
					</div>
					
     				<Modal show={this.state.cantSaveModalShow}
          				dialogClassName="modal-90w"
          				aria-labelledby="example-custom-modal-styling-title">
					<Modal.Header>
						<Modal.Title id="example-custom-modal-styling-title">Can't Save AI Coach</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<p>Please fill out all fields</p>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={this.handleCantSaveModalHide}>OK</Button>
					</Modal.Footer>
					</Modal>

				</SIWrapper>
			)
		}
    }
}


